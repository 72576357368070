var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-header"},[_c('div',{staticClass:"dashboard-header-left"},[_c('portal-target',{attrs:{"name":"destination"}})],1),_vm._v(" "),_c('div',{staticClass:"dashboard-header-right"},[_c('portal-target',{attrs:{"name":"rightdestination"}}),_vm._v(" "),_c('el-popover',{ref:"h__featurePopper",staticClass:"feature-notification-popper-trigger",attrs:{"placement":"bottom","width":"350","trigger":'hover',"disabled":_vm.getNotificationCount === 0},model:{value:(_vm.notificationCountVisible),callback:function ($$v) {_vm.notificationCountVisible=$$v},expression:"notificationCountVisible"}},[_c('notification-view',{attrs:{"item-list":_vm.getFeaturesList}}),_vm._v(" "),_c('er-button',{staticClass:"btn-notification",attrs:{"slot":"reference","type":"text"},slot:"reference"},[_c('el-badge',{staticClass:"item",attrs:{"value":_vm.getNotificationCount,"hidden":_vm.getNotificationCount === 0,"max":9}},[_c('span',{staticClass:"notification-icon-container"},[_c('span',{class:[
                'material-icons-round',
                'notification-active-icon',
                _vm.isfeaturePopperVisible() && 'is-active'
              ]},[_vm._v("\n              fact_check\n            ")]),_vm._v(" "),_c('span',{class:[
                'material-icons-outlined',
                'notification-icon',
                !_vm.isfeaturePopperVisible() && 'is-active'
              ]},[_vm._v("\n              fact_check\n            ")])])])],1)],1),_vm._v(" "),(_vm.doUserHasLocations)?_c('er-dropdown',{attrs:{"placement":"bottom","trigger":"click","listOfItems":_vm.computedUserLocations,"popper-class":"locations-dropdown"},on:{"item-click":_vm.handleChangeInLocation},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
              var item = ref.item;
return [_c('router-link',{staticClass:"location-link",attrs:{"to":{
            query: _vm.routeQuery({ location_id: item.value._id })
          },"tag":"li"}},[_vm._v("\n          "+_vm._s(item.value.name)+"\n        ")])]}}],null,false,1475349954)},[_c('template',{slot:"prefix"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" pin_drop ")])]),_vm._v(" "),_c('template',{slot:"title"},[_c('p',[_vm._v(_vm._s(_vm.currUserLocation.name))])])],2):_vm._e(),_vm._v(" "),_c('er-dropdown',{attrs:{"placement":"bottom","trigger":"click","listOfItems":_vm.langs},on:{"item-click":_vm.handleChangeInLanguage}},[_c('template',{slot:"prefix"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(" g_translate ")])]),_vm._v(" "),_c('template',{slot:"title"},[_c('p',[_vm._v(_vm._s(_vm.langSelected))])])],2),_vm._v(" "),_c('er-dropdown',{staticClass:"profile-drop-down",attrs:{"placement":"bottom","trigger":"click","listOfItems":_vm.profileDropDownItems,"popper-class":"profile-dropdown"},on:{"item-click":_vm.handleNavigation}},[_c('template',{slot:"prefix"},[(!_vm.getProfileUrl)?_c('p',{staticClass:"header-profile-placeholder"},[_vm._v("\n          "+_vm._s(_vm.getFirstCharsOfUserName)+"\n        ")]):_c('el-avatar',{staticClass:"header-profile-photo",attrs:{"size":25,"fit":"fill","src":_vm.getProfileUrl}})],1),_vm._v(" "),_c('span',{staticClass:"el-dropdown-link",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.getUserName))]),_vm._v(" "),_c('template',{slot:"drop-down-items"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-user-solid","title":_vm.getUserName,"command":{path:'/user/settings', query: { tab: 'userProfile' }}}},[_c('router-link',{staticClass:"truncate er-dropdown-item--profile",attrs:{"to":{
              path: '/user/settings',
              query: { tab: 'userProfile' }
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":navigate}},[_vm._v("\n            "+_vm._s(_vm.$t("Usrs_profile"))+"\n          ")])]}}])})],1),_vm._v(" "),_c('el-dropdown-item',{staticClass:"er-dropdown-item--logout"},[_c('i',{staticClass:"el-icon-logout"},[_c('img',{staticClass:"logout-icon",attrs:{"src":require("@/assets/logout.svg")}})]),_vm._v(" "),_c('er-button',{staticClass:"logout-button",attrs:{"type":"text"},on:{"click":_vm.handleSignOutClick}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{staticClass:"button-text",attrs:{"tag":"div"}},[_vm._v(_vm._s(_vm.getLogoutBtnText))])],1)],1)],1)],1)],2),_vm._v(" "),_c("help",{tag:"component",attrs:{"showDialog":_vm.dialogVisible},on:{"close_dialog":_vm.closeDialog}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }