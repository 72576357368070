/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: alertsInfo.js
Description: This file contains all the vuex store functions used in alerts info page
*/
import {
  errorAlertsFactory,
  errorHistoryAlertsFactory
} from "@/model/errorAlerts.js";
import AlertsService from "@/services/DeviceHealthService.js";
import { allDevicesAlertsMetaData } from "@/constants/deviceTypes";
import { cloneDeep } from "lodash";
import UserService from "@/services/userService";
export default {
  namespaced: true,
  state: function() {
    return {
      alertsFilter: {
        POND: [],
        ALERTS: []
      },
      alerts: [],
      alertsHistory: [],
      alertsHistoryDownload: [],
      alertsHistoryDeviceData: {},
      alertsHistoryDeviceDataDownload: {},
      alertsFilterData: [],
      alertsHistoryUsersData: [],
      alertsHistoryUsersDataDownload: [],
      totalDeviceWiseAlertsCount: {},
      clickedAlert: {},
      subUsersData: [],
      loading: false
    };
  },
  getters: {
    getAlertsFilter: state => state.alertsFilter,
    getSubUsersData: state => state.subUsersData,
    getArrAvailDeviceTypes: function(state, getters, rootState, rootGetters) {
      return rootGetters["user/getDeviceTypesEnabledForUser"];
    },
    getArrAvailDevicesMetaData: function(state, getters) {
      const deviceTypeToMetaData = allDevicesAlertsMetaData;
      // device wise priority is based on the order in the getter deviceTypeToMetaData
      return getters.getArrAvailDeviceTypes.map(
        deviceType => deviceTypeToMetaData[deviceType]
      );
    },
    getAllValidErrorAlerts: function(state, getters) {
      const errorAlerts = getters.getArrAvailDeviceTypes.reduce((acc, x) => {
        acc[x] = [];
        return acc;
      }, {});
      getters.getArrAvailDevicesMetaData.reduce(
        (accAllDeviceAlerts, iterObj) => {
          const key = iterObj.bknd_alert_key;
          const device_type = iterObj.device_type;
          state.alerts.forEach(pond => {
            if (pond[key] && pond[key].length !== 0) {
              const arrAlerts = pond[key].reduce((accDeviceAlerts, alert) => {
                const deviceCode = alert.display_code;
                const deviceData = {
                  device_code: deviceCode,
                  device_title: alert.device_title || deviceCode,
                  device_type: device_type,
                  device_id: alert.device_id,
                  battery_voltage: alert.errors_data.map(eachAlert => {
                    if (!eachAlert.details) return;
                    return iterObj.battery_voltage_func(
                      eachAlert.details.display_battery_voltage,
                      eachAlert.details.battery_voltage
                    );
                  })[0]
                };
                const arrAlerts = errorAlertsFactory(
                  pond,
                  alert.errors_data,
                  deviceData
                );
                if (arrAlerts.length > 0) {
                  accDeviceAlerts.push(...arrAlerts);
                }
                return accDeviceAlerts;
              }, []);
              accAllDeviceAlerts[device_type].push(...arrAlerts);
            }
          });
          return accAllDeviceAlerts;
        },
        errorAlerts
      );
      return errorAlerts;
    },
    getTotalErrorAlertCountByDeviceType: function(state, getters) {
      const allValidErrorAlerts = getters.getAllValidErrorAlerts;
      return getters.getArrAvailDeviceTypes.reduce((acc, curr) => {
        acc[curr] = allValidErrorAlerts[curr].length;
        return acc;
      }, {});
    },
    getTotalErrorAlertCountByDeviceTypeHistory: function(state, getters) {
      const allValidErrorAlerts = getters.getValidAlertsFilterByPondIdsHistory;
      return getters.getArrAvailDeviceTypes.reduce((acc, curr) => {
        acc[curr] = allValidErrorAlerts[curr].length;
        return acc;
      }, {});
    },
    getTotalErrorAlertCountByDeviceTypeHistoryDownload: function(
      state,
      getters
    ) {
      const allValidErrorAlerts =
        getters.getValidAlertsFilterByPondIdsHistoryDownload;
      return getters.getArrAvailDeviceTypes.reduce((acc, curr) => {
        acc[curr] = allValidErrorAlerts[curr].length;
        return acc;
      }, {});
    },
    getValidAlertsFilterByPondIds: function(state, getters) {
      const errorAlerts = getters.getArrAvailDeviceTypes.reduce(
        (acc, deviceType) => {
          acc[deviceType] = [];
          return acc;
        },
        {}
      );
      const arrAlerts = getters.getAlertsFilterByPondIds;
      getters.getArrAvailDevicesMetaData.reduce(
        (accAllDeviceAlerts, iterObj) => {
          const key = iterObj.bknd_alert_key;
          const device_type = iterObj.device_type;
          arrAlerts.forEach(pond => {
            if (!pond) return;
            if (pond[key] && pond[key].length !== 0) {
              const arrAlerts = pond[key].reduce((accDeviceAlerts, alert) => {
                const deviceCode =
                  key === "gateway_alerts"
                    ? alert.device_code
                    : alert.display_code;
                const deviceData = {
                  device_code: deviceCode,
                  device_title: alert.device_title || deviceCode,
                  device_type: device_type,
                  device_id: alert.device_id,
                  battery_voltage: alert.errors_data.map(eachAlert => {
                    if (!eachAlert.details) return;
                    return iterObj.battery_voltage_func(
                      eachAlert.details.display_battery_voltage,
                      eachAlert.details.battery_voltage
                    );
                  })[0]
                };
                const arrAlerts = errorAlertsFactory(
                  pond,
                  alert.errors_data,
                  deviceData
                );
                if (arrAlerts.length > 0) {
                  accDeviceAlerts.push(...arrAlerts);
                }
                return accDeviceAlerts;
              }, []);
              accAllDeviceAlerts[device_type].push(...arrAlerts);
            }
          });
          return accAllDeviceAlerts;
        },
        errorAlerts
      );
      return errorAlerts;
    },
    getValidAlertsFilterByPondIdsHistoryDownload: function(state, getters) {
      const errorAlerts = getters.getArrAvailDeviceTypes.reduce(
        (acc, deviceType) => {
          acc[deviceType] = [];
          return acc;
        },
        {}
      );
      const Device_Types = {
        gateway_alerts: "GATEWAY",
        pond_guard_alerts: "POND_GUARD",
        shrimp_talk_alerts: "SHRIMP_TALK",
        pond_mother_alerts: "POND_MOTHER"
      };
      const arrAlerts = getters.getAlertsHistoryDownloadData;
      getters.getArrAvailDevicesMetaData.reduce(
        (accAllDeviceAlerts, iterObj) => {
          const key = Device_Types[iterObj.bknd_alert_key];
          const device_type = iterObj.device_type;
          arrAlerts.forEach(pond => {
            if (!pond) return;
            if (
              pond &&
              getters.getAlertsHistoryDeviceDataDownload[key] &&
              getters.getAlertsHistoryDeviceDataDownload[key].length !== 0
            ) {
              getters.getAlertsHistoryDeviceDataDownload[key].forEach(
                device => {
                  var arrAlerts = [];
                  if (pond.device_id === device._id) {
                    const deviceCode =
                      key === "gateway_alerts"
                        ? pond.device_code
                        : pond.display_code;
                    pond.raw_device_type = key;
                    const deviceData = {
                      device_code: deviceCode || device.code,
                      device_title: device.title || deviceCode,
                      device_type: device_type,
                      device_id: pond.device_id,
                      battery_voltage: device.sensor_values
                        ? device.sensor_values.battery_voltage
                        : ""
                    };
                    arrAlerts = errorHistoryAlertsFactory(pond, deviceData);
                    accAllDeviceAlerts[device_type].push(...arrAlerts);
                  }
                }
              );
            }
          });
          return accAllDeviceAlerts;
        },
        errorAlerts
      );
      return errorAlerts;
    },
    getValidAlertsFilterByPondIdsHistory: function(state, getters) {
      const errorAlerts = getters.getArrAvailDeviceTypes.reduce(
        (acc, deviceType) => {
          acc[deviceType] = [];
          return acc;
        },
        {}
      );
      const Device_Types = {
        gateway_alerts: "GATEWAY",
        pond_guard_alerts: "POND_GUARD",
        shrimp_talk_alerts: "SHRIMP_TALK",
        pond_mother_alerts: "POND_MOTHER"
      };
      const arrAlerts = getters.getAlertsHistoryData;
      getters.getArrAvailDevicesMetaData.reduce(
        (accAllDeviceAlerts, iterObj) => {
          const key = Device_Types[iterObj.bknd_alert_key];
          const device_type = iterObj.device_type;
          arrAlerts.forEach(pond => {
            if (!pond) return;
            if (
              pond &&
              getters.getAlertsHistoryDeviceData[key] &&
              getters.getAlertsHistoryDeviceData[key].length !== 0
            ) {
              getters.getAlertsHistoryDeviceData[key].forEach(device => {
                var arrAlerts = [];
                if (pond.device_id === device._id) {
                  const deviceCode =
                    key === "gateway_alerts"
                      ? pond.device_code
                      : pond.display_code;
                  pond.raw_device_type = key;
                  const deviceData = {
                    device_code: deviceCode || device.code,
                    device_title: device.title || deviceCode,
                    device_type: device_type,
                    device_id: pond.device_id,
                    battery_voltage: device.sensor_values
                      ? device.sensor_values.battery_voltage
                      : ""
                  };
                  arrAlerts = errorHistoryAlertsFactory(pond, deviceData);
                  accAllDeviceAlerts[device_type].push(...arrAlerts);
                }
              });
            }
          });
          return accAllDeviceAlerts;
        },
        errorAlerts
      );
      return errorAlerts;
    },
    // getValidAlertsFilterByPondIdsHistory: function(state, getters) {
    //   const errorAlerts = getters.getArrAvailDeviceTypes.reduce(
    //     (acc, deviceType) => {
    //       acc[deviceType] = [];
    //       return acc;
    //     },
    //     {}
    //   );
    //   const Device_Types = {
    //     gateway_alerts: "GATEWAY",
    //     pond_guard_alerts: "POND_GUARD",
    //     shrimp_talk_alerts: "SHRIMP_TALK",
    //     pond_mother_alerts: "POND_MOTHER"
    //   };
    //   const arrAlerts = getters.getAlertsHistoryData;
    //   getters.getArrAvailDevicesMetaData.reduce(
    //     (accAllDeviceAlerts, iterObj) => {
    //       const key = Device_Types[iterObj.bknd_alert_key];
    //       const device_type = iterObj.device_type;
    //       arrAlerts.forEach(pond => {
    //         if (!pond) return;
    //         if (
    //           pond[key] &&
    //           pond[key].length !== 0 &&
    //           getters.getAlertsHistoryDeviceData[key] &&
    //           getters.getAlertsHistoryDeviceData[key].length !== 0
    //         ) {
    //           getters.getAlertsHistoryDeviceData[key].forEach(device => {
    //             const arrAlerts = pond[key].reduce((accDeviceAlerts, alert) => {
    //               if (alert.device_id === device._id) {
    //                 const deviceCode =
    //                   key === "gateway_alerts"
    //                     ? alert.device_code
    //                     : alert.display_code;
    //                 alert.raw_device_type = key;
    //                 const deviceData = {
    //                   device_code: deviceCode || device.code,
    //                   device_title: device.device_title || deviceCode,
    //                   device_type: device_type,
    //                   device_id: alert.device_id,
    //                   battery_voltage: device.sensor_values
    //                     ? device.sensor_values.battery_voltage
    //                     : ""
    //                   // .map(eachAlert => {
    //                   //   if (!eachAlert) return;
    //                   //   return iterObj.battery_voltage_func(
    //                   //     eachAlert.display_battery_voltage,
    //                   //     eachAlert.battery_voltage
    //                   //   );
    //                   // })[0] : ''
    //                 };
    //                 const arrAlerts = errorAlertsFactory(
    //                   pond,
    //                   [alert],
    //                   deviceData
    //                 );
    //                 if (arrAlerts.length > 0) {
    //                   accDeviceAlerts.push(...arrAlerts);
    //                 }
    //               }
    //               return accDeviceAlerts;
    //             }, []);
    //             accAllDeviceAlerts[device_type].push(...arrAlerts);
    //           });
    //         }
    //       });
    //       return accAllDeviceAlerts;
    //     },
    //     errorAlerts
    //   );
    //   return errorAlerts;
    // },
    getPondAlertsToPondIdMap: function(state) {
      return state.alerts.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
    },
    getAlertsFilterByPondIds(state, getters) {
      const pondIds = state.alertsFilter.POND;
      let arrAlerts = [];
      if (pondIds.length > 0) {
        arrAlerts = pondIds.map(
          pondId => getters.getPondAlertsToPondIdMap[pondId]
        );
      }
      return arrAlerts;
    },
    getFilterAlertGroupByDeviceType(state, getters) {
      const groupAlertByDeviceType = state.alertsFilter.ALERTS.reduce(
        (deviceFitlerAlerts, { device_type, alert_type }) => {
          deviceFitlerAlerts[device_type].add(alert_type);
          return deviceFitlerAlerts;
        },
        getters.getArrAvailDeviceTypes.reduce((acc, deviceType) => {
          acc[deviceType] = new Set();
          return acc;
        }, {})
      );
      for (const key in groupAlertByDeviceType) {
        groupAlertByDeviceType[key] = [...groupAlertByDeviceType[key]];
      }
      return groupAlertByDeviceType;
    },
    getFilterTableData: function(state, getters, rootState, rootGetters) {
      const deviceWiseAlerts = getters.getValidAlertsFilterByPondIds;
      const { getClickedAlert } = getters;
      const groupAlertByDeviceType = state.alertsFilter.ALERTS.reduce(
        (deviceFitlerAlerts, { device_type, alert_type }) => {
          deviceFitlerAlerts[device_type].add(alert_type);
          return deviceFitlerAlerts;
        },
        getters.getArrAvailDeviceTypes.reduce((acc, deviceType) => {
          acc[deviceType] = new Set();
          return acc;
        }, {})
      );
      const allowedAlerts =
        rootGetters["user/getPreferredUnits"].allowed_device_alerts;
      let arrAlerts = allowedAlerts.map(key => {
        const arrAlerts = [...groupAlertByDeviceType[key]];
        return deviceWiseAlerts[key].filter(x =>
          arrAlerts.includes(x.error_code)
        );
      });
      arrAlerts = arrAlerts.flat(1);
      if (Object.keys(getClickedAlert).length > 0) {
        const { alertType, deviceName } = getClickedAlert;
        const filteredAlerts = arrAlerts.filter(
          ({ device_type, error_code }) => {
            return (
              error_code === alertType &&
              device_type.toLowerCase().replace(/_/g, "") ===
                deviceName.toLowerCase()
            );
          }
        );
        if (!filteredAlerts.length > 0) {
          return arrAlerts;
        } else {
          return filteredAlerts;
        }
      } else {
        return arrAlerts;
      }
      // filtered out the alerts
      // return arrAlerts;
    },
    getFilterTableHistoryData: function(state, getters) {
      const deviceWiseAlerts = getters.getValidAlertsFilterByPondIdsHistory;
      // return deviceWiseAlerts;
      const groupAlertByDeviceType = state.alertsFilterData.reduce(
        (deviceFitlerAlerts, { device_type, alert_type }) => {
          deviceFitlerAlerts[device_type].add(alert_type);
          return deviceFitlerAlerts;
        },
        getters.getArrAvailDeviceTypes.reduce((acc, deviceType) => {
          acc[deviceType] = new Set();
          return acc;
        }, {})
      );
      let arrAlerts = Object.keys(groupAlertByDeviceType).map(key => {
        const arrAlerts = [...groupAlertByDeviceType[key]];
        return deviceWiseAlerts[key].filter(x =>
          arrAlerts.includes(x.error_code)
        );
      });
      arrAlerts = arrAlerts.flat(1);
      return arrAlerts;
    },
    getFilterTableHistoryDataDownload: function(state, getters) {
      const deviceWiseAlerts =
        getters.getValidAlertsFilterByPondIdsHistoryDownload;
      const groupAlertByDeviceType = state.alertsFilterData.reduce(
        (deviceFitlerAlerts, { device_type, alert_type }) => {
          deviceFitlerAlerts[device_type].add(alert_type);
          return deviceFitlerAlerts;
        },
        getters.getArrAvailDeviceTypes.reduce((acc, deviceType) => {
          acc[deviceType] = new Set();
          return acc;
        }, {})
      );
      let arrAlerts = Object.keys(groupAlertByDeviceType).map(key => {
        const arrAlerts = [...groupAlertByDeviceType[key]];
        return deviceWiseAlerts[key].filter(x =>
          arrAlerts.includes(x.error_code)
        );
      });
      arrAlerts = arrAlerts.flat(1);
      return arrAlerts;
    },
    getTotalDeviceWiseAlertsCount: function(state) {
      return state.totalDeviceWiseAlertsCount;
    },
    getTotalPondsWithAlerts(state, getters, rootState, rootGetters) {
      const location = rootGetters["user/getCurrUserLocation"];
      const alerts = state.alerts;
      const totalPonds = alerts.filter(
        ({ location_id }) => location_id === location._id
      );
      const alertKeys = getters.getArrAvailDeviceTypes.map(deviceType => {
        return `${deviceType.toLowerCase()}_alerts`;
      });
      const noAlertPonds = alerts.filter(alertsObj => {
        return [
          ...alertKeys.map(key => alertsObj[key]),
          alertsObj.location_id === location._id
        ].every(x => x);
      });
      return totalPonds.length - noAlertPonds.length;
    },
    getFilterPondIdsAlertTypesByDeviceType(state, getters) {
      const totalAlertsCount = getters.getTotalErrorAlertCountByDeviceType;
      return getters.getArrAvailDevicesMetaData.reduce(
        (acc, { key, name, device_type, bknd_total_alerts_device_key }) => {
          let deviceAlerts = cloneDeep(
            getters.getValidAlertsFilterByPondIds[device_type]
          );
          deviceAlerts = deviceAlerts.sort(
            (a, b) => a.user_priority - b.user_priority
          );
          const alertsForADevice = deviceAlerts.reduce(
            (acc, { error_code, device_id, user_priority }) => {
              if (!acc.types[error_code]) {
                acc.types[error_code] = {
                  count: 0,
                  error_code: error_code,
                  user_priority: user_priority,
                  arr_device_ids: []
                };
              }
              acc.types[error_code].count++;
              acc.types[error_code].arr_device_ids.push(device_id);
              return acc;
            },
            {
              types: {},
              total_devices:
                getters.getTotalDeviceWiseAlertsCount[
                  bknd_total_alerts_device_key
                ]
            }
          );
          const fitlerGroupAlerts = getters.getFilterAlertGroupByDeviceType;
          const deviceFilterAlerts = fitlerGroupAlerts[device_type];
          const total_devices_with_alerts = new Set();
          for (const eachAlertType in alertsForADevice.types) {
            if (!deviceFilterAlerts.includes(eachAlertType)) {
              delete alertsForADevice.types[eachAlertType];
            } else {
              alertsForADevice.types[eachAlertType].arr_device_ids.forEach(
                deviceId => {
                  total_devices_with_alerts.add(deviceId);
                }
              );
            }
          }
          acc[device_type] = alertsForADevice;
          acc[device_type].total_devices_with_alerts = [
            ...total_devices_with_alerts
          ].length;
          acc[device_type].count = totalAlertsCount[device_type];
          acc[device_type].device_type = device_type;
          acc[device_type].name = name;
          acc[device_type].key = key;
          return acc;
        },
        {}
      );
    },
    getAllPondsAlertTypesByDeviceType(state, getters) {
      const totalAlertsCount = getters.getTotalErrorAlertCountByDeviceType;
      return getters.getArrAvailDevicesMetaData.reduce(
        (acc, { key, name, device_type, bknd_total_alerts_device_key }) => {
          let deviceAlerts = cloneDeep(
            getters.getAllValidErrorAlerts[device_type]
          );
          deviceAlerts = deviceAlerts.sort(
            (a, b) => a.user_priority - b.user_priority
          );
          const alertsForADevice = deviceAlerts.reduce(
            (acc, { error_code, device_id, user_priority }) => {
              if (!acc.types[error_code]) {
                acc.types[error_code] = {
                  count: 0,
                  error_code: error_code,
                  user_priority: user_priority
                };
              }
              if (!acc.unique_ids.has(device_id)) {
                acc.unique_ids.add(device_id);
                acc.total_devices_with_alerts++;
              }
              acc.types[error_code].count++;
              return acc;
            },
            {
              types: {},
              unique_ids: new Set(),
              total_devices_with_alerts: 0,
              total_devices:
                getters.getTotalDeviceWiseAlertsCount[
                  bknd_total_alerts_device_key
                ]
            }
          );
          acc[device_type] = alertsForADevice;
          acc[device_type].count = totalAlertsCount[device_type];
          acc[device_type].device_type = device_type;
          acc[device_type].name = name;
          acc[device_type].key = key;
          return acc;
        },
        {}
      );
    },
    getAllPondsAlertTypesByDeviceTypeHistory(state, getters) {
      const totalAlertsCount =
        getters.getTotalErrorAlertCountByDeviceTypeHistory;
      return getters.getArrAvailDevicesMetaData.reduce(
        (acc, { key, name, device_type, bknd_total_alerts_device_key }) => {
          let deviceAlerts = cloneDeep(
            getters.getValidAlertsFilterByPondIdsHistory[device_type]
          );
          deviceAlerts = deviceAlerts.sort(
            (a, b) => a.user_priority - b.user_priority
          );
          const alertsForADevice = deviceAlerts.reduce(
            (acc, { error_code, device_id, user_priority }) => {
              if (!acc.types[error_code]) {
                acc.types[error_code] = {
                  count: 0,
                  error_code: error_code,
                  user_priority: user_priority
                };
              }
              if (!acc.unique_ids.has(device_id)) {
                acc.unique_ids.add(device_id);
                acc.total_devices_with_alerts++;
              }
              acc.types[error_code].count++;
              return acc;
            },
            {
              types: {},
              unique_ids: new Set(),
              total_devices_with_alerts: 0,
              total_devices:
                getters.getTotalDeviceWiseAlertsCount[
                  bknd_total_alerts_device_key
                ]
            }
          );
          acc[device_type] = alertsForADevice;
          acc[device_type].count = totalAlertsCount[device_type];
          acc[device_type].device_type = device_type;
          acc[device_type].name = name;
          acc[device_type].key = key;
          return acc;
        },
        {}
      );
    },
    getAllPondsAlertTypesByDeviceTypeHistoryDownload(state, getters) {
      const totalAlertsCount =
        getters.getTotalErrorAlertCountByDeviceTypeHistoryDownload;
      return getters.getArrAvailDevicesMetaData.reduce(
        (acc, { key, name, device_type, bknd_total_alerts_device_key }) => {
          let deviceAlerts = cloneDeep(
            getters.getValidAlertsFilterByPondIdsHistoryDownload[device_type]
          );
          deviceAlerts = deviceAlerts.sort(
            (a, b) => a.user_priority - b.user_priority
          );
          const alertsForADevice = deviceAlerts.reduce(
            (acc, { error_code, device_id, user_priority }) => {
              if (!acc.types[error_code]) {
                acc.types[error_code] = {
                  count: 0,
                  error_code: error_code,
                  user_priority: user_priority
                };
              }
              if (!acc.unique_ids.has(device_id)) {
                acc.unique_ids.add(device_id);
                acc.total_devices_with_alerts++;
              }
              acc.types[error_code].count++;
              return acc;
            },
            {
              types: {},
              unique_ids: new Set(),
              total_devices_with_alerts: 0,
              total_devices:
                getters.getTotalDeviceWiseAlertsCount[
                  bknd_total_alerts_device_key
                ]
            }
          );
          acc[device_type] = alertsForADevice;
          acc[device_type].count = totalAlertsCount[device_type];
          acc[device_type].device_type = device_type;
          acc[device_type].name = name;
          acc[device_type].key = key;
          return acc;
        },
        {}
      );
    },
    getAllPondsWithTitle(state) {
      return state.alerts.map(x => ({ _id: x._id, title: x.title }));
    },
    getPondsHavingAlerts(state, getters) {
      return Object.values(
        Object.values(getters.getAllValidErrorAlerts)
          .flat(1)
          .reduce((acc, x) => {
            acc[x.pond_id] = { _id: x.pond_id, title: x.pond_name };
            return acc;
          }, {})
      );
    },
    getInitialAllAvailableAlertFilters(state, getters) {
      const allAlertsByDeviceType = getters.getAllPondsAlertTypesByDeviceType;
      const alertFilters = [];
      for (const device_type in allAlertsByDeviceType) {
        alertFilters.push(
          Object.keys(allAlertsByDeviceType[device_type].types).map(
            alertType => {
              return {
                device_type,
                alert_type: alertType
              };
            }
          )
        );
      }
      return alertFilters.flat(1);
    },
    getInitialAllAvailableAlertFiltersHistory(state, getters) {
      const allAlertsByDeviceType =
        getters.getAllPondsAlertTypesByDeviceTypeHistory;
      const alertFilters = [];
      for (const device_type in allAlertsByDeviceType) {
        alertFilters.push(
          Object.keys(allAlertsByDeviceType[device_type].types).map(
            alertType => {
              return {
                device_type,
                alert_type: alertType
              };
            }
          )
        );
      }
      return alertFilters.flat(1);
    },
    getInitialAllAvailableAlertFiltersHistoryDownload(state, getters) {
      const allAlertsByDeviceType =
        getters.getAllPondsAlertTypesByDeviceTypeHistoryDownload;
      const alertFilters = [];
      for (const device_type in allAlertsByDeviceType) {
        alertFilters.push(
          Object.keys(allAlertsByDeviceType[device_type].types).map(
            alertType => {
              return {
                device_type,
                alert_type: alertType
              };
            }
          )
        );
      }
      return alertFilters.flat(1);
    },
    getClickedAlert(state) {
      return state.clickedAlert;
    },
    getAlertsHistoryData(state) {
      return state.alertsHistory;
    },
    getAlertsHistoryDownloadData(state) {
      return state.alertsHistoryDownload;
    },
    getAlertsHistoryDeviceData(state) {
      return state.alertsHistoryDeviceData;
    },
    getAlertsHistoryDeviceDataDownload(state) {
      return state.alertsHistoryDeviceDataDownload;
    },
    getAlertsHistoryUsersData(state) {
      return state.alertsHistoryUsersData;
    },
    getAlertsHistoryUsersDataDownload(state) {
      return state.alertsHistoryUsersDataDownload;
    }
  },
  mutations: {
    SET_ALERT_FILTER_SELECTION(state, data) {
      state.alertsFilter[data.filter_type] = data.filters;
      state.alertsFilter = Object.assign({}, state.alertsFilter);
    },
    SET_ALERT_FILTER_SELECTION_HISTORY(state, payload) {
      state.alertsFilterData = payload;
    },
    SET_ALERT_FILTER_SELECTION_HISTORY_DOWNLOAD(state, payload) {
      state.alertsFilterData = payload;
    },
    SET_SUB_USER_DATA(state, payload) {
      state.subUsersData = payload;
    },
    SET_ALL_ALERTS(state, payload) {
      state.alerts = payload;
    },
    SET_ALL_ALERTS_HISTORY(state, payload) {
      state.alertsHistory = payload;
    },
    SET_ALL_ALERTS_HISTORY_DOWNLOAD(state, payload) {
      state.alertsHistoryDownload = payload;
    },
    SET_ALL_ALERTS_HISTORY_DEVICE_DATA(state, payload) {
      state.alertsHistoryDeviceData = payload;
    },
    SET_ALL_ALERTS_HISTORY_DEVICE_DATA_DOWNLOAD(state, payload) {
      state.alertsHistoryDeviceDataDownload = payload;
    },
    SET_ALL_ALERTS_HISTORY_USER_DATA(state, payload) {
      state.alertsHistoryUsersData = payload;
    },
    SET_ALL_ALERTS_HISTORY_USER_DATA_DOWNLOAD(state, payload) {
      state.alertsHistoryUsersDataDownload = payload;
    },
    SET_TOTAL_DEVICES_COUNT(state, totalDeviceCount) {
      state.totalDeviceWiseAlertsCount = totalDeviceCount;
    },
    SET_CLICKED_ALERT(state, clickAlert) {
      const { clickedAlert } = state;

      const { deviceName, alertType } = clickedAlert;

      const {
        deviceName: currentDeviceName,
        alertType: currentAlertType
      } = clickAlert;

      if (Object.keys(clickedAlert).length > 0) {
        // same
        if (
          deviceName === currentDeviceName &&
          alertType === currentAlertType
        ) {
          state.clickedAlert = {};
        } else {
          state.clickedAlert = clickAlert;
        }
      } else {
        state.clickedAlert = clickAlert;
      }
    },
    SET_TABLE_LOADING(state, status) {
      state.loading = status;
    }
  },
  actions: {
    changeAlertsFilterSelection: async (context, data) => {
      context.commit("SET_ALERT_FILTER_SELECTION", data);
    },
    fetchAllAlerts: async function(context, data) {
      const location = context.rootGetters["user/getCurrUserLocation"];
      const response = await AlertsService.fetchLocationAlerts({
        location_id: location._id
      });
      const gatewayAlerts = {
        title: undefined,
        _id: "GATEWAY_GROUP_ID",
        gateway_alerts: response.data.gateway_alerts
      };
      response.data.ponds.push(gatewayAlerts);
      context.commit("SET_ALL_ALERTS", response.data.ponds || []);
      const pondIds = context.getters.getPondsHavingAlerts.map(x => x._id);
      context.commit("SET_ALERT_FILTER_SELECTION", {
        filter_type: "POND",
        filters: pondIds
      });
      const availableFilters =
        context.getters.getInitialAllAvailableAlertFilters;
      context.commit("SET_ALERT_FILTER_SELECTION", {
        filter_type: "ALERTS",
        filters: availableFilters
      });
      context.commit(
        "SET_TOTAL_DEVICES_COUNT",
        response.data.total_devices || {}
      );
    },
    selectAlert: async function(context, data) {
      console.log(data);
      context.commit("SET_CLICKED_ALERT", data);
    },
    fetchAllAlertsHistory: async function(context, { data, type = "" }) {
      const location = context.rootGetters["user/getCurrUserLocation"];
      if (type === "download") {
        const response = await AlertsService.fetchLocationAlertsHistory({
          location_id: location._id,
          request_type: "HISTORY",
          from_date: data.from_date,
          to_date: data.to_date,
          order_by: "pond_title",
          order_type: "asc",
          get_all: true
        });
        context.commit(
          "SET_ALL_ALERTS_HISTORY_DOWNLOAD",
          response.data.data || []
        );
        context.commit(
          "SET_ALL_ALERTS_HISTORY_DEVICE_DATA_DOWNLOAD",
          response.data.device_details || {}
        );
        // fetch getUserProfile from user store
        const profileData = context.rootGetters["user/getUserProfile"];
        let res = [];
        if (response.data.user_details?.length) {
          res = response.data.user_details.reduce((acc, curr) => {
            acc[curr._id] = curr;
            return acc;
          }, []);
          if (!res[profileData._id]) {
            res[profileData._id] = {
              full_name: profileData.full_name,
              _id: profileData._id,
              status: profileData.status,
              disabled: false
            };
          }
        } else {
          res[profileData._id] = {
            full_name: profileData.full_name,
            _id: profileData._id,
            status: profileData.status,
            disabled: false
          };
        }
        context.commit(
          "SET_ALL_ALERTS_HISTORY_USER_DATA_DOWNLOAD",
          Object.values({ ...res })
        );
        const availableFilters =
          context.getters.getInitialAllAvailableAlertFiltersHistoryDownload;
        context.commit(
          "SET_ALERT_FILTER_SELECTION_HISTORY_DOWNLOAD",
          availableFilters
        );
        return response.data;
      } else {
        const response = await AlertsService.fetchLocationAlertsHistory({
          location_id: location._id,
          request_type: "HISTORY",
          from_date: data.from_date,
          to_date: data.to_date,
          page: +data.page,
          limit: +data.limit,
          order_by: data?.order_by,
          order_type: data?.order_type
        });
        // const gatewayAlerts = {
        //   title: undefined,
        //   _id: "GATEWAY_GROUP_ID",
        //   gateway_alerts: response.data.gateway_alerts
        // };
        // response.data.pond_alerts.push(gatewayAlerts);
        context.commit("SET_ALL_ALERTS_HISTORY", response.data.data || []);
        context.commit(
          "SET_ALL_ALERTS_HISTORY_DEVICE_DATA",
          response.data.device_details || {}
        );
        // to do: get profile data and append this
        // [{name: "", value: "", disabled: false}]

        // fetch getUserProfile from user store
        const profileData = context.rootGetters["user/getUserProfile"];
        let res = [];
        if (response.data.user_details?.length) {
          res = response.data.user_details.reduce((acc, curr) => {
            acc[curr._id] = curr;
            return acc;
          }, []);
          if (!res[profileData._id]) {
            res[profileData._id] = {
              full_name: profileData.full_name,
              _id: profileData._id,
              status: profileData.status,
              disabled: false
            };
          }
        } else {
          res[profileData._id] = {
            full_name: profileData.full_name,
            _id: profileData._id,
            status: profileData.status,
            disabled: false
          };
        }
        console.log("res", res);
        // res = Object.values({ ...res });
        context.commit(
          "SET_ALL_ALERTS_HISTORY_USER_DATA",
          Object.values({ ...res })
        );
        // context.commit(
        //   "SET_ALL_ALERTS_HISTORY_USER_DATA",
        //   (response.data.user_details.length > 0 && response.data.user_details) ||
        //     profileDataPayload
        // );
        // const pondIds = context.getters.getPondsHavingAlerts.map(x => x._id);
        // context.commit("SET_ALERT_FILTER_SELECTION", {
        //   filter_type: "POND",
        //   filters: pondIds
        // });
        const availableFilters =
          context.getters.getInitialAllAvailableAlertFiltersHistory;
        context.commit("SET_ALERT_FILTER_SELECTION_HISTORY", availableFilters);
        // context.commit(
        //   "SET_TOTAL_DEVICES_COUNT",
        //   response.data.total_devices || {}
        // );
        return response.data;
      }
    },
    async fetchSubUserDetails(context, params) {
      const location = context.rootGetters["user/getCurrUserLocation"];
      const response = await UserService.fetchSubUserPlainDetails({
        location_id: location._id,
        get_all: true
      });
      const subUserData = response.data.sub_users.reduce((acc, curr) => {
        const user = {
          name: curr.full_name,
          value: curr._id,
          status: curr.status,
          disabled: false
        };
        acc.push(user);
        return acc;
      }, []);
      const skrettingTechnicians = response.data.skretting_technicians.reduce(
        (acc, curr) => {
          const user = {
            name: curr.full_name,
            value: curr._id,
            status: curr.status,
            disabled: false
          };
          acc.push(user);
          return acc;
        },
        []
      );
      const userData = [...subUserData, ...skrettingTechnicians];
      context.commit("SET_SUB_USER_DATA", userData);
    },
    async patchAlertsHistoryData(context, params) {
      const response = await AlertsService.patchAlertsHistoryData(
        params.payload
      );
      return response;
    }
  }
};
