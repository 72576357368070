/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: pond.js
Description: This file contains all the vuex store functions used in realtion to pond
*/
import Pond from "../../model/pond";
import PondsService from "../../services/PondsService";
import { findIndex, nth, cloneDeep } from "lodash";
import { ABW, Samples } from "../../model/abw";
import { alphaNumericComparator, minDateISOString } from "@/utils/commonUtils";
import DeviceHealthService from "../../services/DeviceHealthService";
import dateUtils from "@/utils/dateUtils";
import { deviceTypes } from "@/constants/deviceTypes";
import FeedManagementService from "../../services/FeedManagementService";
import i18n from "@/config/i18n";
const state = {
  ponds: [],
  activePonds: [],
  inactivePonds: [],
  lowDoPondCount: 0,
  unstablePhPondsCount: 0,
  withoutSchedulesPondsCount: [],
  mapPondidPond: new Map(),
  abwSummary: [],
  mapPondDrawnPlace: "",
  feedTypes: [],
  healthData: [],
  currentPondData: {},
  loading: false,
  manualFeedRecords: [],
  aPondManualFeedRecords: [],
  pondWiseDeviceStatus: []
};
const getters = {
  getManualFeedRecords: state => state.manualFeedRecords,
  getAPondManualFeedRecords: state => state.aPondManualFeedRecords,
  getPondDeviceKeysByDevicesAvailable: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const availableDevices = rootGetters["user/getDeviceTypesEnabledForUser"];
    const mapOfPondDeviceKeysToDevice = {
      POND_MOTHER: "pond_mothers",
      POND_GUARD: "pond_guards",
      SHRIMP_TALK: "shrimp_talks",
      FEED_BLOWER: "feed_blowers",
      SHRIMP_SNAP: "shrimp_snaps"
    };
    return availableDevices.reduce((acc, x) => {
      if (mapOfPondDeviceKeysToDevice[x]) {
        acc.push(mapOfPondDeviceKeysToDevice[x]);
      }
      return acc;
    }, []);
  },
  getPonds: (state, getters) => {
    return state.ponds.map(pond => {
      const newPond = cloneDeep(pond);
      getters.getPondDeviceKeysByDevicesAvailable.forEach(function(
        eachDeviceKey
      ) {
        if (eachDeviceKey !== "shrimp_talks") {
          newPond[eachDeviceKey] = (newPond[eachDeviceKey] || []).map(
            eachDevice => {
              eachDevice.title = eachDevice.title
                ? eachDevice.title
                : eachDevice.code;
              return eachDevice;
            }
          );
        }
      });
      return newPond;
    });
  },
  getActivePonds: state => state.activePonds,
  getInactivePonds: state => state.inactivePonds,
  getLowDoPondCount: state => state.lowDoPondCount,
  getUnstablePhPondsCount: state => state.unstablePhPondsCount,
  getWithoutSchedulesPondsCount: state => state.withoutSchedulesPondsCount,
  getMapPondidPond: state => state.mapPondidPond,
  getPondsObj: state => {
    const pondsObj = {};

    state.ponds.forEach(pond => {
      pondsObj[pond._id] = pond;
    });
    return pondsObj;
  },
  getObjPondIdPondWithPmsAndMode: (state, getters, rootState, rootGetters) => {
    const objPondIdPond = {};
    getters.getMapPondidPond.forEach((pond, pond_id) => {
      const newPond = cloneDeep(pond);
      if (
        newPond.pond_mothers.length &&
        (rootGetters["pondmother/getPmIdPm"] || new Map()).size
      ) {
        const pond_mothers = newPond.pond_mothers;
        const newPms = [];
        const pondMode = { farmer: 0, shrimp_talk: 0, manual: 0 };
        for (let i = 0; i < pond_mothers.length; i++) {
          const pm = rootGetters["pondmother/getPmIdPm"].get(
            pond_mothers[i]._id
          );
          if (pm) {
            newPms.push(pm);
            pondMode[pm && pm.managed_by] = 1;
          }
        }
        newPond.pond_mothers = newPms;
        const managedByKeys = Object.keys(pondMode).filter(
          key => pondMode[key] === 1
        );
        newPond.managed_by =
          managedByKeys.length > 1 ? "NONE" : managedByKeys[0];
      }
      objPondIdPond[pond._id] = newPond;
    });
    return objPondIdPond;
  },
  getObjPondIdPondHealthData: state => {
    const objPondIdPondHealthData = {};
    state.healthData.forEach(pondHealthData => {
      objPondIdPondHealthData[pondHealthData._id] = pondHealthData;
    });
    return objPondIdPondHealthData;
  },
  getArrPondDataWithHealth: (state, getters) => {
    return getters.getPonds.map(pond => {
      const nPondObj = cloneDeep(pond);
      const objPondHealthData = getters.getObjPondIdPondHealthData;
      const data = objPondHealthData[nPondObj._id];
      Object.keys(data || {}).forEach(key => {
        nPondObj[key] = data[key];
      });
      return nPondObj;
    });
  },
  getObjPondDataWithHealth: (state, getters) => {
    const objPondDataWithHealth = {};
    state.ponds.forEach(pond => {
      const nPondObj = cloneDeep(pond);
      const objPondHealthData = getters.getObjPondIdPondHealthData;
      const data = objPondHealthData[nPondObj._id];
      Object.keys(data || {}).forEach(key => {
        nPondObj[key] = data[key];
      });
      objPondDataWithHealth[nPondObj._id] = nPondObj;
    });
    return objPondDataWithHealth;
  },
  getPondsInCulture: (state, getters, rootState, rootGetters) => {
    const userTimeZoneString = rootGetters["user/getUserTimeZoneString"];
    const utcISOString = dateUtils.formatTZToISO(
      dateUtils.getCurrDateInGivenTZ(userTimeZoneString),
      userTimeZoneString
    );
    return state.ponds.filter(
      pond =>
        pond.status === "ACTIVE" &&
        pond.post_larva_stocked > 0 &&
        pond.cultivation_date != null &&
        pond.cultivation_date !== utcISOString
    );
  },
  getpondHavingABWWithAbwValues(state, getters, rootState, rootGetters) {
    const abwValues = state.abwSummary || [];
    const pondIds = abwValues.map(abw => abw.pond_id);
    if (abwValues.length > 0) {
      const pondHavingABW = {};
      getters.getMapPondidPond.forEach((pond, key) => {
        if (pond.status === "ACTIVE") {
          const abwIndex = pondIds.indexOf(key);
          const abwObj = new ABW();
          abwObj.pond_id = key;
          abwObj.cultivation_date = pond.cultivation_date;
          abwObj.modified = false;
          if (abwIndex > -1) {
            if (abwValues[abwIndex].abw_data.last_abw_date != null) {
              abwObj.last_abw = abwValues[abwIndex].abw_data.last_abw;
              abwObj.last_abw_date = abwValues[abwIndex].abw_data.last_abw_date;
              abwObj.weight_gain = abwValues[abwIndex].abw_data.abw
                ? abwValues[abwIndex].abw_data.abw - abwObj.last_abw
                : 0;
            }
            const sampleSize = (abwValues[abwIndex].abw_data.samples || [])
              .length;
            abwObj.samples =
              sampleSize > 0
                ? abwValues[abwIndex].abw_data.samples
                : [new Samples()];
            abwObj.abw = abwValues[abwIndex].abw_data.abw;
            abwObj.harvested_abw = abwValues[abwIndex].abw_data.harvested_abw;
            abwObj.survival_percentage =
              abwValues[abwIndex].abw_data.survival_percentage || 100;
          }
          pondHavingABW[key] = abwObj;
        }
      });
      return pondHavingABW;
    }
    return {};
  },
  getPondIDsHavingABW(state) {
    const abwValues = state.abwSummary || [];
    const pondIds = abwValues.map(abw => abw.pond_id);
    return pondIds;
  },
  getCurrentPondData(state) {
    return state.currentPondData;
  },
  getPondWiseDeviceStatus(state) {
    return state.pondWiseDeviceStatus;
  },
  getActivePondDeviceStatus(state, getters) {
    const pondWiseDeviceStatus = getters.getPondWiseDeviceStatus;
    return pondWiseDeviceStatus.filter(x => x.status === "ACTIVE");
  },
  getPondsByPondStatusForDeviceHealth(state, getters) {
    return pondStatus => {
      const pondWiseDeviceStatus = getters.getPondWiseDeviceStatus;
      if (pondStatus === "ALL") return pondWiseDeviceStatus;
      return pondWiseDeviceStatus.filter(x => x.status === pondStatus);
    };
  },
  getPondGuardDeviceStatus(state, getters) {
    return pondStatus => {
      const pondWiseDeviceStatus = getters.getPondsByPondStatusForDeviceHealth(
        pondStatus
      );
      const pgData = pondWiseDeviceStatus
        .map(pond => {
          return (pond.pond_guards || []).map(
            ({
              title,
              code,
              sensor_values,
              utc_last_communicated_at,
              last_communicated_at,
              comm_status
            }) => {
              let battery_voltage;
              if (sensor_values) {
                if (sensor_values.battery_voltage !== undefined) {
                  battery_voltage = sensor_values.battery_voltage;
                }
              }
              return {
                pond_title: pond.title,
                device_type: deviceTypes.POND_GUARD.key,
                device_type_lang: deviceTypes.POND_GUARD.lang,
                device_sort_key: `${pond.title}|${
                  deviceTypes.POND_GUARD.key
                }|${title || code} `,
                battery_voltage,
                title: title || code,
                code,
                date: (sensor_values || { date: minDateISOString }).date,
                utc_date: (sensor_values || { utc_date: minDateISOString })
                  .utc_date,
                utc_last_communicated_at,
                last_communicated_at,
                comm_status: comm_status ? i18n.t("online") : i18n.t("offline")
              };
            }
          );
        })
        .flat(1);
      return pgData;
    };
  },
  getPondMotherDeviceStatus(state, getters) {
    return pondStatus => {
      const pondWiseDeviceStatus = getters.getPondsByPondStatusForDeviceHealth(
        pondStatus
      );
      const pmData = pondWiseDeviceStatus
        .map(pond => {
          return (pond.pond_mothers || []).map(
            ({
              title,
              code,
              feed_limit,
              feed_percentage,
              sensor_values,
              utc_last_communicated_at,
              last_communicated_at,
              comm_status
            }) => {
              let battery_voltage;
              if (sensor_values) {
                if (sensor_values.battery_voltage !== undefined) {
                  battery_voltage = sensor_values.battery_voltage;
                }
                if (sensor_values.display_battery_voltage !== undefined) {
                  battery_voltage = sensor_values.display_battery_voltage;
                }
              }
              return {
                pond_title: pond.title,
                device_type: deviceTypes.POND_MOTHER.key,
                device_type_lang: deviceTypes.POND_MOTHER.lang,
                device_sort_key: `${pond.title}|${
                  deviceTypes.POND_MOTHER.key
                }|${title || code}`,
                battery_voltage,
                feed_limit,
                feed_percentage,
                title: title || code,
                code,
                date: (sensor_values || { date: minDateISOString }).date,
                utc_date: (sensor_values || { utc_date: undefined }).utc_date,
                utc_last_communicated_at,
                last_communicated_at,
                comm_status: comm_status ? i18n.t("online") : i18n.t("offline")
              };
            }
          );
        })
        .flat(1);
      return pmData;
    };
  },
  getShrimpTalkDeviceStatus(state, getters) {
    return pondStatus => {
      const pondWiseDeviceStatus = getters.getPondsByPondStatusForDeviceHealth(
        pondStatus
      );
      const stData = pondWiseDeviceStatus
        .map(pond => {
          return (pond.shrimp_talks || []).map(
            ({
              title,
              shrimptalk_display_code,
              sensor_values,
              utc_last_communicated_at,
              last_communicated_at,
              comm_status
            }) => {
              let battery_voltage;
              if (sensor_values) {
                if (sensor_values.battery_voltage !== undefined) {
                  battery_voltage = sensor_values.battery_voltage + 1;
                }
              }
              return {
                pond_title: pond.title,
                device_type: deviceTypes.SHRIMP_TALK.key,
                device_type_lang: deviceTypes.SHRIMP_TALK.lang,
                battery_voltage,
                device_sort_key: `${pond.title}|${
                  deviceTypes.SHRIMP_TALK.key
                }|${title || shrimptalk_display_code}`,
                title: title || shrimptalk_display_code,
                code: shrimptalk_display_code,
                date: (sensor_values || { date: minDateISOString }).date,
                utc_date: (sensor_values || { utc_date: undefined }).utc_date,
                utc_last_communicated_at,
                last_communicated_at,
                comm_status: comm_status ? i18n.t("online") : i18n.t("offline")
              };
            }
          );
        })
        .flat(1);
      return stData;
    };
  },
  getFeedBlowerDeviceStatus(state, getters) {
    return pondStatus => {
      const pondWiseDeviceStatus = getters.getPondsByPondStatusForDeviceHealth(
        pondStatus
      );
      const fbData = pondWiseDeviceStatus
        .map(pond => {
          return (pond.feed_blowers || []).map(
            ({
              title,
              code,
              feed_percentage,
              sensor_values,
              utc_last_communicated_at,
              last_communicated_at,
              comm_status
            }) => {
              let battery_voltage;
              if (sensor_values) {
                if (sensor_values.battery_voltage !== undefined) {
                  battery_voltage = sensor_values.battery_voltage;
                }
                if (sensor_values.display_battery_voltage !== undefined) {
                  battery_voltage = sensor_values.display_battery_voltage;
                }
              }
              return {
                pond_title: pond.title,
                device_type: deviceTypes.FEED_BLOWER.key,
                device_type_lang: deviceTypes.FEED_BLOWER.lang,
                battery_voltage,
                feed_percentage,
                device_sort_key: `${pond.title}|${
                  deviceTypes.FEED_BLOWER.key
                }|${title || code}`,
                title: title || code,
                code,
                date: (sensor_values || { date: minDateISOString }).date,
                utc_date: (sensor_values || { utc_date: undefined }).utc_date,
                utc_last_communicated_at,
                last_communicated_at,
                comm_status: comm_status ? i18n.t("online") : i18n.t("offline")
              };
            }
          );
        })
        .flat(1);
      return fbData;
    };
  }
};
const mutations = {
  SET_PONDS_WITHOUT_SCHEDULES_COUNT(state, countData) {
    if (countData != null) {
      state.withoutSchedulesPondsCount = countData;
    } else {
      state.withoutSchedulesPondsCount = null;
    }
  },
  SET_UNSTABLE_PH_PONDS_COUNT(state, countData) {
    if (countData != null) {
      state.unstablePhPondsCount = countData;
    } else {
      state.unstablePhPondsCount = null;
    }
  },
  SET_LOW_DO_PONDS_COUNT(state, countData) {
    if (countData != null) {
      state.lowDoPondCount = countData;
    } else {
      state.lowDoPondCount = null;
    }
  },
  SET_PONDS_DATA(state, pondsData) {
    if (pondsData != null) {
      const pondArr = pondsData.map(pond => {
        const pondObj = new Pond();
        pondObj.castToPondObj(pond);
        return pondObj;
      });
      state.ponds = pondArr.sort((a, b) =>
        alphaNumericComparator(a.title, b.title)
      );
    } else {
      state.ponds = null;
    }
  },
  SET_ACTIVE_PONDS_DATA(state, pondsData) {
    if (pondsData != null) {
      const pondArr = [];
      pondsData.forEach(pond => {
        const pondObj = new Pond();
        pondObj.castToPondObj(pond);
        pondArr.push(pondObj);
      });
      state.activePonds = pondArr;
    } else {
      state.activePonds = null;
    }
  },
  SET_INACTIVE_PONDS_DATA(state, pondsData) {
    if (pondsData != null) {
      const pondArr = [];
      pondsData.forEach(pond => {
        const pondObj = new Pond();
        pondObj.castToPondObj(pond);
        pondArr.push(pondObj);
      });
      state.inactivePonds = pondArr;
    } else {
      state.inactivePonds = null;
    }
  },
  SET_MAP_PONDID_POND(state) {
    const mapPondidPond = new Map();
    state.ponds.forEach(pond => {
      mapPondidPond.set(pond.pond_id, pond);
    });
    state.mapPondidPond = mapPondidPond;
  },
  SET_MAP_ACTIVE_PONDID_POND(state) {
    const mapPondidPond = new Map();
    state.activepPonds.forEach(pond => {
      mapPondidPond.set(pond.pond_id, pond);
    });
    state.mapPondidPond = mapPondidPond;
  },
  PUSH_NEW_POND(state, pondData) {
    const pondObj = new Pond();
    pondObj.castToPondObj(pondData);
    state.ponds.push(pondObj);
    if (!state.mapPondidPond.get(pondObj.pond_id)) {
      state.mapPondidPond.set(pondObj.pond_id, pondObj);
    }
  },
  PUSH_UPDATED_POND(state, pondData) {
    const pondObj = new Pond();
    pondObj.castToPondObj(pondData);
    const index = findIndex(state.ponds, function(pond) {
      return pond.pond_id === pondData.pond_id;
    });
    state.ponds.splice(index, 1, pondObj);
    if (state.mapPondidPond.get(pondObj.pond_id)) {
      state.mapPondidPond.set(pondObj.pond_id, pondObj);
    }
  },

  SET_FEED_TYPES(state, feedTypes) {
    state.feedTypes = feedTypes;
  },
  SET_POND_HEALTH_DATA(state, healthData) {
    state.healthData = healthData;
  },
  SET_ABW_SUMMARY(state, abwSummary) {
    state.abwSummary = abwSummary;
  },
  SET_POND_DATA(state, currentPondData) {
    const newPond = new Pond();
    newPond.castToPondObj(currentPondData);
    state.currentPondData = newPond;
  },
  SET_MANUAL_FEED_RECORDS(state, arrFeedRecords) {
    state.manualFeedRecords = arrFeedRecords;
  },
  SET_A_POND_MANUAL_FEED_RECORDS(state, arrFeedRecords) {
    state.aPondManualFeedRecords = arrFeedRecords;
  },
  SET_POND_WISE_DEVICE_STATUS(state, pondWiseDeviceStatus) {
    state.pondWiseDeviceStatus = pondWiseDeviceStatus;
  }
};
const actions = {
  fetchNoOfPondsWithoutSchedules: async (context, params = {}) => {
    const response = await PondsService.fetchNoOfPondsWithoutSchedules(params);
    context.commit("SET_PONDS_WITHOUT_SCHEDULES_COUNT", response.data.data);
  },
  fetchUnstablePhPondCount: async (context, params = {}) => {
    const response = await PondsService.fetchUnstablePhPondCount(params);
    context.commit("SET_UNSTABLE_PH_PONDS_COUNT", response.data.count);
  },
  fetchPondWiseDeviceStatus: async (context, params = {}) => {
    const response = await DeviceHealthService.fetchDeviceStatusForPonds(
      params
    );
    context.commit("SET_POND_WISE_DEVICE_STATUS", response.data.ponds);
  },
  fetchLowDoPondCount: async (context, params = {}) => {
    const response = await PondsService.fetchLowDoPondCount(params);
    context.commit("SET_LOW_DO_PONDS_COUNT", response.data.count);
  },
  fetchAllPonds: async (context, params = {}) => {
    console.log("params", params);
    const response = await PondsService.fetchAllPonds(params);
    context.commit("SET_PONDS_DATA", response.data.ponds);
    context.commit("SET_MAP_PONDID_POND");
    return {
      message: response.data.message,
      totalPonds: response.data.total,
      ponds: response.data.ponds
    };
  },
  fetchStAndPmLogs: async (
    context,
    { stId, from_date, to_date, queryParams }
  ) => {
    const response = await PondsService.fetchShrimpTalkAndPondMotherLog(stId, {
      from_date,
      to_date,
      queryParams
    });
    return response?.data;
  },
  fetchActivePonds: async (context, params = {}) => {
    const response = await PondsService.fetchActivePonds(params);
    context.commit("SET_ACTIVE_PONDS_DATA", response.data.ponds);
    return {
      message: response.data.message,
      totalPonds: response.data.total
    };
  },
  fetchInactivePonds: async (context, params = {}) => {
    const response = await PondsService.fetchInactivePonds(params);
    context.commit("SET_INACTIVE_PONDS_DATA", response.data.ponds);
    return {
      message: response.data.message,
      totalPonds: response.data.total
    };
  },
  fetchAPond: async function(context, pondId) {
    const response = await PondsService.fetchAPond(pondId);
    context.commit("SET_POND_DATA", response.data.pond);
    return response.data.pond;
  },
  fetchPondHealthData: async (context, params) => {
    const response = await PondsService.fetchPondHealthData(params);
    context.commit("SET_POND_HEALTH_DATA", response.data.ponds_summary);
    return response.data;
  },
  savePondDetails: async (context, pondDetails) => {
    const response = await PondsService.savePondDetails(pondDetails);
    context.commit("PUSH_NEW_POND", response.data.pond);
    const pond = nth(context.state.ponds, -1);
    return { pond, message: response.data.message };
  },
  updatePondDetails: async (context, pondDetails) => {
    const response = await (pondDetails.status === "RESET"
      ? PondsService.deleteAllDevicesInPond(pondDetails.pond_id)
      : PondsService.updateAPondDetails(pondDetails.pond_id, pondDetails));
    return response.data;
  },
  enablePond: async (context, pondDetails) => {
    // enablePondService
    const response = await PondsService.enablePondService(
      pondDetails.pond_id,
      pondDetails
    );
    return response.data;
  },
  startCultureDetails: async (context, pondDetails) => {
    const response = await PondsService.startCultureDetails(pondDetails);
    return { message: response.data.message };
  },
  fetchABWSummary: async (context, params = {}) => {
    const response = await PondsService.fetchABWSummary(params);
    const abwValues = response.data.ponds;
    context.commit("SET_ABW_SUMMARY", abwValues);
    return response;
  },
  saveABWValues: async (context, abwSamples) => {
    const response = PondsService.saveABWValues(abwSamples);
    return response;
  },
  fetchPondLatestABWValues: async function(context, pondId) {
    const response = await PondsService.fetchPondLatestABWValues(pondId);
    return response.data.pond.latest_records;
  },
  fetchPondABWValues: async function(context, { pondId, ...params }) {
    const response = await PondsService.fetchPondABWValues(pondId, params);
    return response.data.ponds_abw_samples;
  },
  /**
   * Actions for Manual Feeding
   */
  fetchAllManualFeedingRecords: async function(context, params) {
    const response = await FeedManagementService.fetchAllManualFeedingRecords(
      params
    );
    context.commit(
      "SET_MANUAL_FEED_RECORDS",
      response.data.ponds_manual_feed_entries
    );
    return response.data.message;
  },
  fetchAPondManualFeedingRecords: async function(
    context,
    { pondId, params, commitToStore = false }
  ) {
    const response = await FeedManagementService.fetchAPondManualFeedingRecords(
      pondId,
      params
    );
    if (commitToStore) {
      context.commit(
        "SET_A_POND_MANUAL_FEED_RECORDS",
        response.data.ponds_manual_feed_entries
      );
    }
    return response.data.ponds_manual_feed_entries;
  },
  saveManualFeedingRecords: async function(context, feedRecords) {
    const response = await FeedManagementService.saveManualFeedingRecords(
      feedRecords
    );
    return response.data.message;
  },
  fetchAllHarvestLogs: async function(context, params) {
    const response = await PondsService.fetchAllHarvestLogs(params);
    return response.data.harvest_logs;
  },
  saveHarvestLog: async function(context, harvestLog) {
    const response = await PondsService.saveHarvestLog(harvestLog);
    return response;
  },
  updateHarvestLog: async function(context, harvestLog) {
    const response = await PondsService.updateHarvestLog(harvestLog);
    return response;
  },
  deleteHarvestLog: async function(context, harvest_id) {
    const response = await PondsService.deleteHarvestLog(harvest_id);

    return response;
  },
  cultureChange: function(context, culture_name) {
    context.commit("SET_CULTURE_ID", culture_name);
  },

  deleteABWRecord: async function(context, abwId) {
    const response = await PondsService.deleteABWRecord(abwId);
    return response.data.message;
  },
  deleteFeedRecord: async function(context, manualFeedRecordId) {
    const response = await PondsService.deleteFeedRecord(manualFeedRecordId);

    return response.data.message;
  },
  updateTestPonds: async (context, payload) => {
    const response = await PondsService.updateTestPonds(payload);
    return response;
  },
  updatePauseAlerts: async (context, payload) => {
    const response = await PondsService.updatePauseAlerts(payload);
    return response;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
