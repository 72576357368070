/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: userAccessManagementMixin.js
Description: This mixin contains the common functionality related to the access management in the pondlogs customer site.
*/
import { mapGetters } from "vuex";
import { permissionsToBKPermissions as permissions } from "@/middleware/pageAccessManager";
import { highPriorityUserTypes } from "../middleware/pageAccessManager";

const userAccessManagementMixin = {
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters("user", {
      getViewPermissions: "getViewPermissions",
      getUpdatePermissions: "getUpdatePermissions",
      getDeletePermissions: "getDeletePermissions",
      isReadOnly: "isReadOnly",
      getCurrUserType: "getCurrUserType",
      doRestrictHighPriorityUsers: "doRestrictHighPriorityUsers"
    }),
    $gblUAMGetAccessDeniedMsg() {
      return this.$t(
        "you-may-not-have-the-appropriate-permissions-to-perform-the-operation"
      );
    },
    $gblUAMUsrRoleToUsrRoleDetailsObj() {
      return {
        POND_OWNER: {
          type_name: "Usrs_owner",
          description: "Usrs_owner_full_access_msg",
          add_btn_text: "Usrs_add_ownr",
          value: "POND_OWNER"
        },
        POND_MANAGER: {
          type_name: "Comn_manager",
          description: "Comn_mangers_have_access_msg",
          add_btn_text: "Usrs_add_mng",
          value: "POND_MANAGER"
        },
        POND_TECHNICIAN: {
          type_name: "Usrs_pond_techno",
          description: "Usrs_pond_technician_description",
          add_btn_text: "Usrs_add_tech",
          value: "POND_TECHNICIAN"
        },
        POND_WORKER: {
          type_name: "Usrs_pond_worker",
          description: "Usrs_pond_worker_description",
          add_btn_text: "Usrs_add_wrkr",
          value: "POND_WORKER"
        },
        NO_ACCESS: {
          type_name: "Comn_no_access",
          description: "Usrs_no_access_usr",
          value: "NO_ACCESS"
        }
      };
    },
    $gblUAMPermissionsToPermissionDetailsList() {
      return [
        {
          name: this.$t("Comn_manage_overview"),
          key: "MANAGE_OVERVIEW",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_OVERVIEW.VIEW
            }
          ]
        },
        {
          name: this.$t("Comn_manage_culture"),
          key: "MANAGE_CULTURE",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_CULTURE.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_CULTURE.UPDATE
            }
          ]
        },
        {
          name: this.$t("permisssion_manage_schedules"),
          key: "MANAGE_SCHEDULES",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_SCHEDULES.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_SCHEDULES.UPDATE
            }
          ]
        },
        {
          name: this.$t("Comn_manage_devices"),
          key: "MANAGE_DEVICES",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_DEVICES.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_DEVICES.UPDATE
            },
            {
              name: "DELETE",
              permissions: permissions.MANAGE_DEVICES.DELETE
            }
          ]
        },
        {
          name: this.$t("Comn_manage_abw"),
          key: "MANAGE_ABW",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_ABW.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_ABW.UPDATE
            },
            {
              name: "DELETE",
              permissions: permissions.MANAGE_ABW.DELETE
            }
          ]
        },
        {
          name: this.$t("Comn_manage_harvest"),
          key: "MANAGE_HARVEST",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_HARVEST.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_HARVEST.UPDATE
            },
            {
              name: "DELETE",
              permissions: permissions.MANAGE_HARVEST.DELETE
            }
          ]
        },
        {
          name: this.$t("Comn_manage_medicine"),
          key: "MANAGE_MEDICINE",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_MEDICINE.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_MEDICINE.UPDATE
            },
            {
              name: "DELETE",
              permissions: permissions.MANAGE_MEDICINE.DELETE
            }
          ]
        },
        {
          name: this.$t("manage-lab-tests"),
          key: "MANAGE_LAB_TESTS",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_LAB_TESTS.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_LAB_TESTS.UPDATE
            },
            {
              name: "DELETE",
              permissions: permissions.MANAGE_LAB_TESTS.DELETE
            }
          ]
        },
        {
          name: this.$t("manage-stock"),
          key: "MANAGE_STOCK",
          availableActions: [
            {
              name: "VIEW",
              permissions: permissions.MANAGE_STOCK.VIEW
            },
            {
              name: "UPDATE",
              permissions: permissions.MANAGE_STOCK.UPDATE
            },
            {
              name: "DELETE",
              permissions: permissions.MANAGE_STOCK.DELETE
            }
          ]
        }
      ];
    },
    $gblUAMGetDefaultPermissions() {
      // Technician - Selected locations & Manage Devices permission enabled (by default)
      // Pond Worker - Selected locations & Manage schedules and Manage ABW enabled (by default)
      return {
        POND_TECHNICIAN: [
          // DEVICES
          // "VIEW_POND_GUARDS",
          // "VIEW_POND_MOTHERS",
          // "VIEW_SHRIMP_TALKS",
          // "VIEW_GATEWAYS",
          // "VIEW_POND_ALERTS",
          // "VIEW_PONDS",
          // "VIEW_WATER_QUALITY_SUMMARY",
          // "UPDATE_POND_GUARDS",
          // "UPDATE_POND_MOTHERS",
          // "UPDATE_SHRIMP_TALKS",
          // "UPDATE_GATEWAYS",
          // // "DELETE_POND_GUARDS",
          // // "DELETE_POND_MOTHERS",
          // // "DELETE_SHRIMP_TALKS",
          // "VIEW_CULTURE"
        ],

        POND_WORKER: [
          // SCHEDULES
          "VIEW_SCHEDULES",
          "VIEW_POND_GUARDS",
          "VIEW_PONDS",
          "VIEW_MANUAL_FEED",
          "VIEW_GATEWAYS",
          "VIEW_POND_MOTHERS",
          "VIEW_SHRIMP_TALKS",
          "VIEW_RESOURCES",
          "UPDATE_SCHEDULES",
          "UPDATE_SHRIMP_TALKS",
          "UPDATE_POND_MOTHERS",
          "UPDATE_GATEWAYS",
          "DELETE_SCHEDULES",
          // ABW
          "VIEW_POND_SUMMARY",
          "VIEW_PONDS",
          "VIEW_ABW",
          "VIEW_CULTURE",
          "UPDATE_ABW",
          "DELETE_ABW"
        ]
      };
    },
    $gblUAMIsHighPriorityUser() {
      return highPriorityUserTypes.includes(this.getCurrUserType);
    }
  },
  methods: {
    $gblUAMIsHighPriorityUsersRestricted() {
      if (this.doRestrictHighPriorityUsers) {
        throw {
          type: "ACCESS_DENIED",
          errors: [
            {
              message: this.$gblUAMGetAccessDeniedMsg
            }
          ]
        };
      }
    },
    $gblUAMFilterItemsUserCanView(objItems) {
      return Object.keys(objItems).reduce((acc, currItem) => {
        const keyToValidateAccess = objItems[currItem].keyToValidateAccess;
        let authorizations = objItems[currItem][keyToValidateAccess];
        let canViewItem = false;
        if (this.$commonUtils.getType(authorizations) === "object") {
          authorizations = authorizations.VIEW;
        }
        const methodToValidate =
          keyToValidateAccess === "PERMISSIONS"
            ? x => this.getViewPermissions[x]
            : x => x === this.getCurrUserType;
        canViewItem = authorizations.some(methodToValidate);

        if (canViewItem) {
          acc[currItem] = objItems[currItem];
        }
        return acc;
      }, {});
    },
    $gblUAMCanUserEdit(itemObj) {
      if (this.isReadOnly) {
        throw {
          type: "ACCESS_DENIED",
          errors: [
            {
              message: this.$gblUAMGetAccessDeniedMsg
            }
          ]
        };
      }
      const keyToValidateAccess = itemObj.keyToValidateAccess;
      let authorizations = itemObj[keyToValidateAccess];
      let canEdit = false;
      if (this.$commonUtils.getType(authorizations) === "object") {
        authorizations = authorizations.UPDATE || [];
      }
      if (authorizations.length > 0) {
        const methodToValidate =
          keyToValidateAccess === "PERMISSIONS"
            ? x => this.getUpdatePermissions[x]
            : x => x === this.getCurrUserType;
        canEdit = authorizations.some(methodToValidate);
      }
      if (!canEdit) {
        throw {
          type: "ACCESS_DENIED",
          errors: [
            {
              message: this.$gblUAMGetAccessDeniedMsg
            }
          ]
        };
      }
    },
    $gblUAMCanUserDelete(itemObj) {
      const keyToValidateAccess = itemObj.keyToValidateAccess;
      let authorizations = itemObj[keyToValidateAccess];
      let canEdit = false;
      if (this.$commonUtils.getType(authorizations) === "object") {
        authorizations = authorizations.DELETE || [];
      }
      if (authorizations.length > 0) {
        const methodToValidate =
          keyToValidateAccess === "PERMISSIONS"
            ? x => this.getDeletePermissions[x]
            : x => x === this.getCurrUserType;
        canEdit = authorizations.some(methodToValidate);
      }

      if (!canEdit) {
        throw {
          type: "ACCESS_DENIED",
          errors: [
            {
              message: this.$gblUAMGetAccessDeniedMsg
            }
          ]
        };
      }
    }
  }
};

export default userAccessManagementMixin;
