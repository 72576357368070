var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{class:_vm.computedTableTypeContainerClass,attrs:{"id":this.uniqueId}},[_c('data-tables-server',{ref:"dataTables",staticClass:"device-apis-table er-data-tables-server",attrs:{"data":_vm.tableData,"total":_vm.total,"size":_vm.size,"page-size":_vm.pageSize,"pagination-props":_vm.$constants.table['pagination-props'],"table-props":_vm.computedTableProps},on:{"query-change":_vm.onQueryChange,"cell-click":_vm.handleCellClick,"expand-change":_vm.handleExpandChange}},[_c('div',{attrs:{"slot":"empty"},slot:"empty"},[_c('span',[_c('img',{staticClass:"no-data__img",attrs:{"src":require("@/assets/no_data.svg")}})]),_vm._v(" "),_c('span',{staticClass:"no-data__text"},[_c('h4',[_vm._v(_vm._s(_vm.$t("Comn_no_data_connection")))])])]),_vm._v(" "),_vm._l((_vm.columns),function(column,key){return [(
          _vm.selectedColumnHeadersSortable
          ? _vm.selectedColumnHeaders.includes(key)
          : Object.keys(_vm.computedColumnsSelected).includes(key)
      )?_c('el-table-column',{directives:[{name:"show",rawName:"v-show",value:(column.visible),expression:"column.visible"}],key:("" + key),attrs:{"prop":column.prop,"label":column.label,"sortable":column.sortable || false,"sort-by":column.sortBy,"sortMethod":column.sortMethod,"width":column.width || '',"min-width":column.minWidth || '',"align":column.align || 'center',"fixed":column.fixed || false,"filters":column.filters,"filter-method":column.filterMethod},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_vm._t((((column.slot || key).toLowerCase()) + "_header"),[_vm._v("\n          "+_vm._s(column.label)+"\n          "+_vm._s(column.sub_label)+"\n          ")],{"header":{
              column: scope.column,
              $index: scope.$index,
              label: column.label,
              sub_label: column.sub_label || '',
          }})]}},{key:"default",fn:function(scope){return [_vm._t((column.slot || key).toLowerCase(),[(
              _vm.getRowValue(scope.row, column.prop, column.type).type ===
              'array'
              )?[_c('el-row',{attrs:{"type":"flex","gutter":"5px"}},_vm._l((_vm.getRowValue(
                  scope.row,
                  column.prop,
                  column.type
                  ).value),function(val,tagIndex){return _c('el-tag',{key:tagIndex,attrs:{"size":"mini","effect":"dark"}},[_vm._v(_vm._s(val))])}),1)]:[_vm._v("\n              "+_vm._s(_vm.getRowValue(scope.row, column.prop, column.type).value)+"\n          ")]],{"row":{
              data: scope.row,
              path: column.prop,
              $index: scope.$index,
          }})]}}],null,true)},[_vm._v(" "),_vm._v(" "),_vm._t(((key.toLowerCase()) + "_NestedColumn"))],2):_vm._e()]}),_vm._v(" "),_vm._t("actionColumn",[(_vm.actionColumn)?_c('el-table-column',{attrs:{"label":_vm.$t('Comn_actions')}},[_c('el-button',{attrs:{"icon":"el-icon-setting","size":"small","disabled":true}})],1):_vm._e()]),_vm._v(" "),(_vm.expandColumn)?_c('el-table-column',{attrs:{"label":"","type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._t("expandColumn",null,{"row":{
          data: scope.row,
          $index: scope.$index,
          }})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('template',{slot:"append"},[_vm._t("append")],2),_vm._v(" "),_c('div',{staticClass:"el-pagination__leftwrapper",attrs:{"slot":"pagination"},slot:"pagination"},[_vm._t("pagination")],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }