/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: userPreferenceMixin.js
Description: This mxin contains the common functionality related to the managing the preference all over the pondlogs customer site
*/
import { mapGetters } from "vuex";
import filterUtils, { convertUnits } from "@/utils/filterUtils";
import { getType } from "@/utils/commonUtils";
const upm__applyConversion = function(values, prevUnits, newUnits) {
  if (prevUnits === newUnits) return values;
  return Array.isArray(values)
    ? values.map((eachValue, index) => {
        return convertUnits(
          eachValue,
          Array.isArray(prevUnits) ? prevUnits[index] : prevUnits,
          Array.isArray(newUnits) ? newUnits[index] : newUnits
        );
      })
    : convertUnits(values, prevUnits, newUnits);
};
const upm__applyOperation = function(convertedValues, operation) {
  if (operation) {
    return operation(convertedValues);
  } else if (Array.isArray(convertedValues)) {
    return convertedValues.reduce((acc, value) => (acc += value), 0);
  }
  return convertedValues;
};
const upm__applyModifiers = function(
  convertedValues,
  modifiers,
  modifiersParams
) {
  return modifiers.reduce((acc, formatFunc) => {
    if (isNaN(acc)) {
      return acc;
    }
    acc = filterUtils[formatFunc](acc, ...(modifiersParams[formatFunc] || []));
    return acc;
  }, convertedValues);
};
const upm__setupUiComponent = function(
  convertedValues,
  actualValue,
  newUnits,
  ui
) {
  if ((!ui.value && !ui.units) || (!ui.unitsOnEmpty && !actualValue)) {
    return convertedValues;
  }
  const unitsTag = `<span class="units">${newUnits}</span>`;
  const valueTag = unitType => {
    return `<p class="value-units value" ${
      unitType === "DATA_ATR" ? `data-units=${newUnits}` : ""
    }> ${convertedValues} 
        ${unitType === "TAG" ? unitsTag : ""}
        </p>`;
  };
  if (ui.value && ui.units) {
    return valueTag(ui.units);
  }
  if (ui.value) return valueTag(false);
  return "";
};
const upm__trimSpacesInStr = function(str) {
  return str.trim().replaceAll(/\s+/g, " ");
};
const userPreferenceMixin = {
  data: function() {
    return {
      upm__mapPropertyToDataKeys: {
        code: "device_code",
        alias_name: "device_title"
      },
      upm__backendUnits: {
        biomass_units: "kg",
        abw_and_growth_units: "g",
        feed_units: "kg",
        pond_area_units: "ha",
        temperature_units: "c",
        do_units: "mg/l",
        biomass_per_area_units: { biomass_units: "kg", pond_area_units: "ha" }
      }
    };
  },
  directives: {
    upm__units: {
      componentUpdated: function(el, binding, vnode, oldVnode) {
        const {
          value: values,
          displayFormatter: unitsDisplayFormatter = value => value,
          valueDisplayFormatter = value => value,
          previousunits: prevUnits,
          units: newUnits,
          modifiersParams
        } = binding.value;
        const ui = {
          ...{ units: "TAG", value: true, unitsOnEmpty: false },
          ...binding.value.ui
        };
        let convertedValues = upm__applyConversion(values, prevUnits, newUnits);
        convertedValues = upm__applyOperation(
          convertedValues,
          binding.value.operation
        );
        const valueBeforeModifiers =
          getType(values) === "number" ? +convertedValues : convertedValues;
        convertedValues = upm__applyModifiers(
          convertedValues,
          Object.keys(binding.modifiers),
          modifiersParams
        );

        el.innerHTML = upm__setupUiComponent(
          valueDisplayFormatter(convertedValues),
          valueBeforeModifiers,
          unitsDisplayFormatter(newUnits),
          ui
        );
      },
      inserted: function(el, binding, vnode, oldVnode) {
        const {
          value: values,
          displayFormatter: unitsDisplayFormatter = value => value,
          valueDisplayFormatter = value => value,
          previousunits: prevUnits,
          units: newUnits,
          modifiersParams
        } = binding.value;
        let convertedValues = upm__applyConversion(values, prevUnits, newUnits);
        const ui = {
          ...{ units: "TAG", value: true, unitsOnEmpty: false },
          ...binding.value.ui
        };
        convertedValues = upm__applyOperation(
          convertedValues,
          binding.value.operation
        );
        const valueBeforeModifiers = convertedValues;
        convertedValues = upm__applyModifiers(
          convertedValues,
          Object.keys(binding.modifiers),
          modifiersParams
        );
        el.innerHTML = upm__setupUiComponent(
          valueDisplayFormatter(convertedValues),
          valueBeforeModifiers,
          unitsDisplayFormatter(newUnits),
          ui
        );
      }
    },
    upm__nounits: {
      componentUpdated: function(el, binding, vnode, oldVnode) {
        let {
          value: values,
          displayFormatter = value => value,
          units: newUnits
        } = binding.value;
        values = Object.keys(binding.modifiers).reduce((acc, formatFunc) => {
          acc = filterUtils[formatFunc](
            acc,
            ...binding.value.modifiersParams[formatFunc]
          );
          return acc;
        }, values);
        el.innerHTML = `<p class="value"> ${values} <span class="units">${displayFormatter(
          newUnits
        )}</span></p>`;
      },
      inserted: function(el, binding, vnode, oldVnode) {
        let {
          value: values,
          displayFormatter = value => value,
          units: newUnits
        } = binding.value;
        values = Object.keys(binding.modifiers).reduce((acc, formatFunc) => {
          acc = filterUtils[formatFunc](
            acc,
            ...binding.value.modifiersParams[formatFunc]
          );
          return acc;
        }, values);
        el.innerHTML = `<p class="value"> ${values} <span class="units">${displayFormatter(
          newUnits
        )}</span></p>`;
      }
    }
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    upm__showFeedGapField: function() {
      const mapStatusValues = {
        SHOW: true,
        HIDE: false
      };
      return mapStatusValues[this.getPreferredUnits.feed_gap || "HIDE"];
    },
    upm__getPrefDeviceIdentityKey() {
      return this.upm__mapPropertyToDataKeys[
        this.getPreferredUnits.device_name
      ];
    },
    upm__getFormatDateAndTimeString: function() {
      return `${this.getPreferredUnits.date_format} ${this.getPreferredUnits.time_format}`;
    },
    upm__getFormatDateString: function() {
      return `${this.getPreferredUnits.date_format}`;
    },
    upm__getFormatTimeString: function() {
      return `${this.getPreferredUnits.time_format}`;
    },
    upm__getChunkSizeByDeviceIdentityKey() {
      return this.upm__getPrefDeviceIdentityKey ===
        this.upm__mapPropertyToDataKeys.code
        ? 1
        : -1;
    },
    upm__feedUnits() {
      return "kg";
    },
    upm__getPondSizeUnits() {
      return this.getPreferredUnits.pond_area_units;
    }
  },
  methods: {
    upm__convertUnits: convertUnits,
    upm__applyConversion,
    upm__trimSpacesInStr,
    upm__getValue: function(item) {
      const key = this.upm__mapPropertyToDataKeys[
        this.getPreferredUnits.device_name
      ];
      return item && (item[key] || item[this.upm__mapPropertyToDataKeys.code]);
    },
    upm__setDataKeys(code, title) {
      this.upm__mapPropertyToDataKeys.code = code;
      this.upm__mapPropertyToDataKeys.alias_name = title;
    }
  }
};
export default userPreferenceMixin;
