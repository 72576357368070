/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: errorHandlerMixin.js
Description: This mixin is useful for handling the common functionality related to error management for a component
*/
import axios from "axios";
import { get } from "lodash";
const errorHandlerMixin = {
  data: function() {
    return {
      ehm__errMessagesObject: null,
      ehm__unhandledErrorMessage: "",
      ehm__isNetworkError: false,
      ehm__errorCode: null
    };
  },
  computed: {
    ehm__unhandledErrorMessageV2: {
      get: function() {
        return this.ehm__unhandledErrorMessage;
      },
      set: function(value) {
        this.ehm__unhandledErrorMessage = value;
      }
    },
    spanValue() {
      if (window.innerWidth <= 600) {
        return 24; // For small screens
      } else if (window.innerWidth <= 960) {
        return 24; // For medium screens
      } else if (window.innerWidth <= 1280) {
        return 12; // For large screens
      } else {
        return 10; // For extra-large screens
      }
    }
  },
  methods: {
    ehm__handleAlertClose() {
      this.ehm__unhandledErrorMessage = "";
    },
    ehm__clearErrorMessages(initErrorObject = {}, initErrorStr = "") {
      this.ehm__errMessagesObject = initErrorObject;
      this.ehm__unhandledErrorMessage = initErrorStr;
    },
    ehm__ruleValidator(payloadItem, validationRules, validationType) {
      const validationTypeToMessage = ({
        validation_type,
        field_lang_key,
        item_type_lang_key,
        item_title,
        threshold_value,
        other_field_lang_key
      }) => {
        const messages = {
          REQUIRED: `${this.$t("Comn_must_not_be_empty", {
            field: this.$t(field_lang_key)
          })} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`,
          PM_REQUIRED: `${this.$t("Comn_must_not_be_empty", {
            field: this.$t(field_lang_key)
          })}`,
          MIN: `${this.$tc("Comn_must_greater_than", 2, {
            field: this.$t(field_lang_key),
            threshold: threshold_value
          })} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`,
          MAX: `${this.$tc("Comn_must_less_than", 2, {
            field: this.$t(field_lang_key),
            threshold: threshold_value
          })} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`,
          MIN_EQUAL: `${this.$tc("kg_must_greater_than_or_equal", 1, {
            field: this.$t(field_lang_key),
            threshold: threshold_value
          })}`,
          // ${this.$t("for_item_with_id", {
          //   item: this.$t(item_type_lang_key),
          //   code: item_title
          // })}
          MAX_EQUAL: `${this.$tc("kg_must_less_than_or_equal", 1, {
            field: this.$t(field_lang_key),
            threshold: threshold_value
          })} `,
          // ${this.$t("for_item_with_id", {
          //   item: this.$t(item_type_lang_key),
          //   code: item_title
          // })}
          LESS_THAN_OTHER_FIELD: `${this.$tc("Comn_must_less_than", 1, {
            field: this.$t(field_lang_key),
            other_field: this.$t(other_field_lang_key)
          })} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`,
          GREATER_THAN_OTHER_FIELD: `${this.$tc("Comn_must_greater_than", 1, {
            field: this.$t(field_lang_key),
            other_field: this.$t(other_field_lang_key)
          })} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`,
          LESS_THAN_EQUAL_OTHER_FIELD: `${this.$tc(
            "Comn_must_less_than_or_equal",
            0,
            {
              field: this.$t(field_lang_key),
              other_field: this.$t(other_field_lang_key)
            }
          )} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`,
          GREATER_THAN_EQUAL_OTHER_FIELD: `${this.$tc(
            "Comn_must_greater_than_or_equal",
            0,
            {
              field: this.$t(field_lang_key),
              other_field: this.$t(other_field_lang_key)
            }
          )} ${this.$t("for_item_with_id", {
            item: this.$t(item_type_lang_key),
            code: item_title
          })}`
        };
        return messages[validation_type];
      };
      const validationTypeToValidator = {
        REQUIRED: value => !value && value !== 0,
        PM_REQUIRED: value => !value && value !== 0,
        MIN: (value, min) => value < min,
        MAX: (value, max) => value > max,
        MIN_EQUAL: (value, min) => value < min,
        MAX_EQUAL: (value, max) => value > max,
        LESS_THAN_OTHER_FIELD: (value, min) => value < min,
        GREATER_THAN_OTHER_FIELD: (value, max) => value > max,
        LESS_THAN_EQUAL_OTHER_FIELD: (value, min) => value <= min,
        GREATER_THAN_EQUAL_OTHER_FIELD: (value, max) => value >= max
      };
      const errors = [];
      validationRules.forEach(fieldItem => {
        // break out with one field and one issue
        const field = fieldItem.field;
        if (field) {
          fieldItem.rules.every(rule => {
            const value = this.$lodash.get(payloadItem, field);
            const validator = validationTypeToValidator[rule.validation_type];
            const message = validationTypeToMessage(rule);
            switch (rule.validation_type) {
              case "REQUIRED":
                if (validator(value)) {
                  errors.push({ message });
                }
                break;
              case "PM_REQUIRED":
                if (validator(value)) {
                  errors.push({ message });
                }
                break;
              case "MIN":
                if (validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "MAX":
                if (validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "MIN_EQUAL":
                if (validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "MAX_EQUAL":
                if (validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "GREATER_THAN_OTHER_FIELD":
                if (!validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "LESS_THAN_OTHER_FIELD":
                if (!validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "GREATER_THAN_EQUAL_OTHER_FIELD":
                if (!validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
              case "LESS_THAN_EQUAL_OTHER_FIELD":
                if (!validator(value, rule.threshold_value)) {
                  errors.push({ message });
                }
                break;
            }

            // break if one validation issue found
            if (errors.length > 0) {
              return false;
            } else {
              return true;
            }
          });
        }
      });
      return errors;
    },
    ehm__errorMessages(err, isAuthenticated = false, notifyErrors = false) {
      if (!err) return;
      if (axios.isCancel(err)) return;
      if (err === "cancel") return;
      if (err.type === "FAIL_TO_SAVE") {
        this.ehm__errorFailToSave(err.errors);
        return;
      }
      if (err.type === "ACCESS_DENIED") {
        this.ehm__errorAccessDenied(err.errors);
        return;
      }
      if (err.response != null || err.status != null) {
        this.ehm__errorCode = err.response.status || err.status;
        try {
          switch (err.response.status || err.status) {
            case 422:
              this.ehm__error422Handler(err, notifyErrors);
              break;
            case 409:
              this.ehm__error409Handler(err);
              break;
            case 404:
              this.$router.push("404");
              break;
            case 403:
              this.ehm__error403Handler(err, isAuthenticated);
              break;
            case 401:
              this.ehm__error401Handler(err, isAuthenticated);
              break;
            case 400:
              this.ehm__error400Handler(err, isAuthenticated);
              break;
            case 500:
            case 501:
            case 502:
              this.ehm__error500Handler(err, isAuthenticated);
              break;
            default:
              console.error(err);
              break;
          }
        } catch (err) {
          console.log("Some thing wond");
        }
      } else if (!navigator.onLine) {
        console.log("offline");
      } else if (err.request) {
        console.log(err.request, "Pondlogs");
        this.ehm__isNetworkError = true;
        this.ehm__unhandledErrorMessage = this.$t(
          "Something_went_wrong_try_again"
        );
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong_try_again"),
          duration: 5000,
          type: "error"
        });
      } else {
        console.log(err);
        // Something happened in setting up the request that triggered an Error
        this.ehm__isNetworkError = true;
        this.ehm__unhandledErrorMessage = this.$t(
          "Something_went_wrong_try_again"
        );
        this.$notify({
          title: this.$t("failed"),
          message: this.$t("Something_went_wrong_try_again"),
          duration: 5000,
          type: "error"
        });
        console.error("Pondlogs Error", err.message);
      }
      console.log(err.config);
    },
    ehm__error422Handler: function(err, notifyErrors) {
      if (err.response.data.errors != null) {
        if (notifyErrors) {
          const errorDetails = get(err, "response.data.errors.details");
          if (Array.isArray(errorDetails)) {
            this.ehm__errorFailToSave(
              errorDetails.map(x => ({ message: x.message }))
            );
          }
          return;
        }
        err.response.data.errors.details.forEach(errorDetail => {
          if (errorDetail.is_nested) {
            const keys = errorDetail.path;
            let previousKey = this.ehm__errMessagesObject;
            for (let i = 0; i < keys.length - 1; i++) {
              if (typeof previousKey[keys[i]] === "undefined") {
                previousKey[keys[i]] = {};
              }
              previousKey = previousKey[keys[i]];
            }
            if (
              previousKey[keys[keys.length - 1]] === null ||
              previousKey[keys[keys.length - 1]] === ""
            ) {
              previousKey[keys[keys.length - 1]] = errorDetail;
            }
          } else {
            if (
              this.ehm__errMessagesObject[errorDetail.key] &&
              this.ehm__errMessagesObject[errorDetail.key] !== ""
            ) {
              return;
            }
            this.ehm__errMessagesObject[errorDetail.key] = errorDetail;
          }
        });
      }
    },
    ehm__error403Handler: function(err, isAuthenticated) {
      const tokenExpiredErrorNames = [
        "TokenExpiredError",
        "JsonWebTokenError",
        "No Authorization Token",
        "jwt expired",
        "Invalid Access Token"
      ];
      if (isAuthenticated) {
        if (tokenExpiredErrorNames.indexOf(err.response.data.message) !== -1) {
          this.$store.dispatch("auth/signOut");
          this.$router.push("/sign-in");
        } else {
          this.ehm__unhandledErrorMessage =
            err.response.message || err.response.data.message;
        }
      } else {
        this.ehm__unhandledErrorMessage =
          err.response.message || err.response.data.message;
      }
    },
    ehm__error401Handler: function(err, isAuthenticated) {
      const tokenExpiredErrorNames = [
        "TOKEN_EXPIRED",
        "INVALID_OAUTH_DETAILS",
        "EMAIL_VERIFICATION_FAILED",
        "INVALID_ACCESS_TOKEN",
        "UNAUTHORIZED",
        "RESET_PASSWORD_FAILED"
      ];
      if (isAuthenticated) {
        if (
          tokenExpiredErrorNames.indexOf(err.response.data.error_code) !== -1
        ) {
          this.$store.dispatch("auth/signOut");
          this.$router.push("/sign-in");
        }
      } else {
        this.ehm__unhandledErrorMessage =
          err.response.data.message || this.$t("unauthenticated_error_message");
        this.$store.dispatch("auth/signOut");
        if (this.$route.path !== "/sign-in") {
          this.$router.push("/sign-in");
        }
      }
    },
    ehm__error400Handler: function(err, isAuthenticated) {
      this.ehm__unhandledErrorMessage =
        this.$t("Something_went_wrong_try_again") || err.response.data.message;
    },
    ehm__error409Handler: function(err) {
      console.log(err.response.data);
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          // errorInnerHtml += '<li>' + el.message + '</li>'
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        this.ehm__unhandledErrorMessage = err.response.data.message;
      }
    },
    ehm__error500Handler: function() {
      this.$notify({
        title: this.$t("failed"),
        message: this.$t("Something_went_wrong_try_again"),
        duration: 5000,
        type: "error"
      });
    },
    ehm__errorFailToSave: async function(errors) {
      try {
        const h = this.$createElement;
        await this.$msgbox({
          title: this.$t("Comn_error"),
          message: h("div", null, [
            h("span", { class: { "popup-error__header": true } }, [
              h("p", { class: { "popup-error__header-title": true } }, [
                " " + this.$t("Comn_clear_errors_msg") + ":"
              ])
            ]),
            h(
              "ul",
              { class: { "popup-error-lists": true } },
              errors.map(x => {
                return h("li", null, x.message);
              })
            )
          ]),
          confirmButtonText: "OK",
          closeOnClickModal: false
        });
      } catch (err) {
        console.log(err);
      }
    },
    ehm__errorAccessDenied: async function(errors) {
      const h = this.$createElement;
      return this.$msgbox({
        title: this.$t("Comn_error"),
        message: h("div", null, [
          h("span", { class: { "popup-error-lists": true } }, errors[0].message)
        ]),
        confirmButtonText: "OK",
        closeOnClickModal: false
      });
    }
  }
};

export default errorHandlerMixin;
