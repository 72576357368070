<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erFormItem.vue
Description: This file contains component which is the extension of the element ui form item component to use as a base component
-->
<template>
  <el-form-item class="er-form-item" v-bind="$attrs" v-on="inputListeners">
    <slot></slot>
    <slot name="label"></slot>
  </el-form-item>
</template>

<script>
export default {
  inheritAttrs: false,
  data: function () {
    return {};
  },
  computed: {
    inputListeners: function () {
      const vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/mixins";
.er-form-item {
  .el-form-item__content {
    @include responsiveProperty(height, 2.2rem, 2.3em, 1.8rem);
    @include responsiveProperty(line-height, 2.2rem, 2.3em, 1.8rem);
  }
  .el-form-item__label {
    @include responsiveProperty(height, 2.2rem, 2.3em, 1.8rem);
    @include responsiveProperty(line-height, 2.2rem, 2.3em, 1.8rem);
  }
  .el-input__inner {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    // border-radius: unset;
    @include responsiveProperty(font-size, 1em, 1.1em, 1.3em);
    @include responsiveProperty(height, 2.2rem, 2.3em, 1.8rem);
    @include responsiveProperty(line-height, 2.2rem, 2.3em, 1.8rem);
  }
  .el-form-item__error {
    padding-left: 3em;
    text-align: left;
  }
  .er-input.er-input--password:after {
    content: "";
    position: absolute;
    width: var(--width);
    background-color: var(--color);
    height: 2px;
    bottom: 0px;
    left: var(--left);
    padding-left: 0px;
    transition: width 0.5s ease-out;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    background-color: unset;
    border: none;
    padding: 0 10px;
  }
  .er-select {
    .el-input {
      display: inline-flex;
      flex-direction: row-reverse;
      .el-input__inner {
        padding-left: 15px;
        @include responsiveProperty(font-size, 1.1em, 1.1em, 1.3em);
        @include responsiveProperty(height, 2rem, 2rem, 1.8rem);
        @include responsiveProperty(line-height, 2rem, 2rem, 1.8rem);
      }
      .el-input__prefix {
        left: 0px;
        position: relative;
        width: 40px;
        text-align: center;
        padding: 0px 1.5px;
        @include responsiveProperty(height, 2rem, 2rem, 1.9rem);
        @include responsiveProperty(line-height, 2rem, 2rem, 2.1rem);
      }
    }
  }
}
</style>
