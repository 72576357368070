<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: erDataTableServer.vue
Description: This file is the extenstion of functionality of data-tables components. It has features such as merging rows, highlight searched components, sorting, and styling cell based on the data type
-->
<!--
For Changing the table Columns values structure using slot with the name of the its label
* label name should be a spaced string
* slot name will be in lowercased, snake case
 -->
<template>
  <el-row :class="computedTableTypeContainerClass" :id="this.uniqueId">
    <data-tables-server
        ref="dataTables"
        :data="tableData"
        :total="total"
        :size="size"
        :page-size="pageSize"
        :pagination-props="$constants.table['pagination-props']"
        :table-props="computedTableProps"
        @query-change="onQueryChange"
        @cell-click="handleCellClick"
        @expand-change="handleExpandChange"
        class="device-apis-table er-data-tables-server"
    >
    <div slot="empty">
        <span>
        <img class="no-data__img" src="@/assets/no_data.svg" />
        </span>
        <span class="no-data__text">
        <h4>{{ $t("Comn_no_data_connection") }}</h4>
        </span>
    </div>

    <template v-for="(column, key) in columns">
        <el-table-column
        :key="`${key}`"
        v-show="column.visible"
        v-if="
            selectedColumnHeadersSortable
            ? selectedColumnHeaders.includes(key)
            : Object.keys(computedColumnsSelected).includes(key)
        "
        :prop="column.prop"
        :label="column.label"
        :sortable="column.sortable || false"
        :sort-by="column.sortBy"
        :sortMethod="column.sortMethod"
        :width="column.width || ''"
        :min-width="column.minWidth || ''"
        :align="column.align || 'center'"
        :fixed="column.fixed || false"
        :filters="column.filters"
        :filter-method="column.filterMethod"
        >
        <template v-slot:header="scope">
            <slot
            :name="`${(column.slot || key).toLowerCase()}_header`"
            v-bind:header="{
                column: scope.column,
                $index: scope.$index,
                label: column.label,
                sub_label: column.sub_label || '',
            }"
            >
            {{ column.label }}
            {{ column.sub_label }}
            </slot>
        </template>

        <template slot-scope="scope">
            <slot
            :name="(column.slot || key).toLowerCase()"
            v-bind:row="{
                data: scope.row,
                path: column.prop,
                $index: scope.$index,
            }"
            >
            <template
                v-if="
                getRowValue(scope.row, column.prop, column.type).type ===
                'array'
                "
            >
                <el-row type="flex" gutter="5px">
                <el-tag
                    size="mini"
                    effect="dark"
                    v-for="(val, tagIndex) in getRowValue(
                    scope.row,
                    column.prop,
                    column.type
                    ).value"
                    :key="tagIndex"
                    >{{ val }}</el-tag
                >
                </el-row>
            </template>
            <template v-else>
                {{ getRowValue(scope.row, column.prop, column.type).value }}
            </template>
            </slot>
        </template>
        <slot :name="`${key.toLowerCase()}_NestedColumn`"></slot>
        </el-table-column>
    </template>
    <slot name="actionColumn">
        <el-table-column :label="$t('Comn_actions')" v-if="actionColumn">
        <el-button
            icon="el-icon-setting"
            size="small"
            :disabled="true"
        ></el-button>
        </el-table-column>
    </slot>
    <el-table-column label v-if="expandColumn" type="expand">
        <template slot-scope="scope">
        <slot
            name="expandColumn"
            v-bind:row="{
            data: scope.row,
            $index: scope.$index,
            }"
        ></slot>
        </template>
    </el-table-column>
    <template slot="append">
        <slot name="append"></slot>
    </template>
    <div slot="pagination" class="el-pagination__leftwrapper">
        <slot name="pagination"></slot>
    </div>
    </data-tables-server>
  </el-row>
</template>

<script>
import table from "@/store/components/table";
import filtersMixin from "@/mixins/filtersMixin";
import searchMixin from "@/mixins/searchMixin";
import dateUtils from "@/utils/dateUtils";
export default {
  mixins: [filtersMixin, searchMixin],
  props: {
    columns: {
      required: true,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 10
    },
    selectedHeaders: Array,
    selectColumn: {
      default: false,
    },
    tableData: {
      required: true,
      default: () => [],
    },
    size: {
      default: "",
    },
    searchOnTableData: {
      default: "",
    },

    merge: {
      default: () => ({}),
      validator: function (value) {
        // value should of form {startColumnNumber:1,endColumnNumber:1}
        // TODO: unique id is required to apply merging for table properly
        const requiredKeys = ["startColumnNumber", "endColumnNumber"];
        return Object.keys(value).every((x) => requiredKeys.includes(x));
      },
    },
    actionColumn: {
      required: true,
      default: true,
    },
    expandColumn: {
      required: false,
      default: false,
    },
    searchField: {
      default: "",
    },
    currentPageChange: {
      default: () => () => {},
    },
    sizeChangeHandler: {
      default: () => () => {},
    },
    elTableProps: {
      default: () => ({}),
    },
    type: {
      default: "none",
    },
    filters: {
      default: () => [],
    },
    sortChangeEventHandler: {
      default: () => () => {},
    },
    sortMethodForColumns: {
      default: () => ({}),
    },
    layout: {
      default: "table,tool,pagination",
    },
    uniqueId: {
      required: true,
      default: "",
    },
    paginationSlot: {
      default: false,
    },
    selectedColumnHeaders: {
      required: false,
      default: () => [],
    },
    selectedColumnHeadersSortable: {
      required: false,
      default: false,
    },
    total: {
      required: true,
      default: () => 0
    },
    height: {
      required: false,
      default: undefined
    },
    tableProps: {
      default: () => ({})
    },
  },
  data: function () {
    return {
      searchString: "",
      query: {},
      page: 1,
      limit: 10,
      pageloading: false,
      columnKeysSelected: Object.keys(this.columns || {}).filter(
        (colKey) => this.columns[colKey].required
      ),
      // columnKeysSelected: []
      fuseSearchObj: undefined,
      TableTypeClass: {
        "card-row-table": "card-row-table",
        "blue-header-table": "blue-header-table",
        "white-header-table": "white-header-table",
        "transparent-header-table": "transparent-header-table",
        "white-header-table-inner": "white-header-table-inner",
        "card-auto-height-scrollable": "card-auto-height-scrollable",
      },
    };
  },
  computed: {
    tableDataArray() {
      if (this.searchOnTableData.length && this.fuseSearchObj) {
        const matchedResults = this.sm__getFilterDataBy(
          this.fuseSearchObj,
          this.searchOnTableData
        );
        return matchedResults.map((x) => x.item);
      } else {
        return this.getInitialTableData;
      }
    },
    getTableLayout() {
      return this.tableData.length > 0 ? this.layout : 'table,tool';
    },
    getInitialTableData() {
      return this.tableData;
    },
    getColumnsToSearch() {
      return Object.values(this.columns)
        .filter((x) => x.enableSearch)
        .map((y) => y.searchProperty);
    },
    getTotalRowCount() {
      return this.$store.getters[`${this.getStoreKey}/getTotalRowCount`];
    },
    columnToFixedStatusMap() {
      return Object.values(this.columns).reduce((acc, column, index) => {
        acc[index + 1] = column.fixed;
        return acc;
      }, {});
    },
    computedColumnsSelected: function () {
      this.$nextTick(() => {
        if (this.$refs.dataTables) {
          this.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
      const arrColKeys = this.columnKeysSelected;
      if (arrColKeys.length === 0) return this.columns;
      const colObj = {};
      arrColKeys.forEach((colKey) => {
        colObj[colKey] = this.columns[colKey];
      });
      // this.columnsSelected = arrColKeys;
      return colObj;
    },
    computedTableTypeClass() {
      return [
        this.TableTypeClass[this.type] || "",
        this.computedTableProps.hasNestedColumn ? "no-borders" : "",
      ].join(" ");
    },
    computedTableTypeContainerClass() {
      return [
        "er-data-tables",
        this.TableTypeClass[this.type] + "__wrapper" || "",
        this.computedTableProps.hasNestedColumn ? "no-borders" : "",
      ].join(" ");
    },
    computedTableProps() {
       return {
        height: this.height,
        headerCellClassName: 'settings-table-header-cell',
        ...this.tableProps
      };
    },
    getStoreKey() {
      return `${this.uniqueId}-table`;
    }
  },
  watch: {
    total: function(val) {
      this.handlePaginationHideAndUnhide();
      this.$nextTick(() => {
        if (this.$refs.dataTables) {
          this.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    }
  },
  created() {
    this.pageloading = this.loading;
    if (!this.$store.hasModule(`${this.uniqueId}-table`)) {
      this.$store.registerModule(`${this.uniqueId}-table`, table);
    }
  },
  mounted() {
    this.registerFuseSearch();
    if (Object.keys(this.merge).length > 0) {
      this.$nextTick(() => {
        const noOfColumns =
          Object.keys(this.columns).length + (this.actionColumn ? 0 : 1);
        this.merge_row(
          this.merge.startColumnNumber,
          this.merge.endColumnNumber,
          noOfColumns
        );
      });
    }
  },
  updated() {
    if (Object.keys(this.merge).length > 0) {
      const noOfColumns =
        Object.keys(this.columns).length + (this.actionColumn ? 0 : 1);
      this.merge_row(
        this.merge.startColumnNumber,
        this.merge.endColumnNumber,
        noOfColumns
      );
    }
  },
  beforeDestroy() {
    if (this.$store.hasModule(`${this.uniqueId}-table`)) {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.$store.unregisterModule(`${this.uniqueId}-table`);
    }
  },
  methods: {
    handlePaginationHideAndUnhide() {
      const ele = document.getElementsByClassName('pagination-bar');
      if (this.total === 0) {
        ele.item(0).style.display = 'none';
      } else {
        ele.item(0).style.display = 'block';
      }
    },
    registerFuseSearch() {
      this.fuseSearchObj = this.sm__initFuseSearch(
        this.$lodash.cloneDeep(this.tableData),
        this.getColumnsToSearch
      );
      this.sm__registerWatcherToInitFuseOn("tableData", () => {
        this.fuseSearchObj = this.sm__initFuseSearch(
          this.$lodash.cloneDeep(this.tableData),
          this.getColumnsToSearch
        );
      });
    },
    isKeyExists(item, key) {
      if (!item) return false;
      if (!item[key]) return false;
      return true;
    },
    getRowValue(object, path, type) {
      const value =
        typeof this.$lodash.result(object, path) === "undefined"
          ? "--"
          : this.$lodash.result(object, path);

      if (type === "date") {
        return value && value !== "--"
          ? {
              type: "string",
              value: dateUtils.formatDate(
                new Date(value),
                this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_TIME
              ),
            }
          : { type: "string", value: "--" };
      }
      if (Array.isArray(value)) {
        return { type: "array", value };
      }
      return {
        type: "string",
        value: Number.isFinite(value) ? Number(value).toFixed(2) : value,
      };
    },
    onQueryChange(queryInfo) {
      switch (queryInfo.type) {
        case "sort":
          this.sort(queryInfo.sort.prop, queryInfo.sort.order);
          break;
        case "size":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "page":
          this.pagination(queryInfo.page, queryInfo.pageSize);
          break;
        case "init":
          this.query.page = 1;
          this.query.limit = this.pageSize;
          break;
        default:
          throw new Error(
            "Not implemented onquerychange for " + queryInfo.type
          );
      }
      this.$emit("loadData", this.query, '');
    },
    sort(property, orderType) {
      this.query.order_by = property;
      if (orderType === 'descending') {
        this.query.order_type = 'desc';
      } else if (orderType === 'ascending') {
        this.query.order_type = 'asc';
      } else if (orderType === null) {
        delete this.query.order_type;
        delete this.query.order_by;
      }
    },
    pagination(page, pageSize) {
      this.query.limit = pageSize;
      this.query.page = page;
      this.page = page;
      this.limit = pageSize;
      // this.applyFilters({ value: pageSize, type: "limit" });
      // this.applyFilters({ value: page, type: "page" });
    },
    handleCellClick(row, column, cell, event) {
      this.$emit("cell-click", row, column, cell, event);
    },
    handleRowClick(row, column, event) {
      this.$emit("row-click", row, column, event);
    },
    handleExpandChange(row, expandedRows) {
      this.$emit("expand-change", row, expandedRows);
    },
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    handleFilteredData(filterData) {
      this.$nextTick(() => {
        if (Object.keys(this.merge).length) {
          const noOfColumns =
            Object.keys(this.columns).length + (this.actionColumn ? 0 : 1);
          this.merge_row(
            this.merge.startColumnNumber,
            this.merge.endColumnNumber,
            noOfColumns
          );
        }
      });
    },
    handleSortChange() {
      this.$nextTick(async () => {
        await this.sortChangeEventHandler();
        if (Object.keys(this.merge).length) {
          const noOfColumns =
            Object.keys(this.columns).length + (this.actionColumn ? 0 : 1);
          this.merge_row(
            this.merge.startColumnNumber,
            this.merge.endColumnNumber,
            noOfColumns
          );
        }
      });
    },
    handleCurrentPageChange() {
      this.$nextTick(async () => {
        await this.currentPageChange();
        if (Object.keys(this.merge).length) {
          const noOfColumns =
            Object.keys(this.columns).length + (this.actionColumn ? 0 : 1);
          this.merge_row(
            this.merge.startColumnNumber,
            this.merge.endColumnNumber,
            noOfColumns
          );
        }
      });
    },
    handlePageSizeChange() {
      this.$nextTick(async () => {
        await this.sizeChangeHandler();
        if (Object.keys(this.merge).length) {
          const noOfColumns =
            Object.keys(this.columns).length + (this.actionColumn ? 0 : 1);
          this.merge_row(
            this.merge.startColumnNumber,
            this.merge.endColumnNumber,
            noOfColumns
          );
        }
      });
    },
    merge_row(startColumn, endColumn, noOfColumns) {
      const rowNodesArr = [];
      const columnNo = endColumn;
      for (let colStart = startColumn; colStart <= columnNo; colStart++) {
        const tableBodyWrapper = this.columnToFixedStatusMap[colStart]
          ? "el-table__fixed-body-wrapper"
          : "el-table__body-wrapper";
        const rowNodes = document.querySelectorAll(
          `#${this.uniqueId}.er-data-tables-server div[class*='${tableBodyWrapper}'] td:nth-child(${colStart})[class*='el-table_']:not(.is-hidden)`
        );
        rowNodesArr.push(rowNodes);
      }
      const startRow = 0;
      const length = rowNodesArr[columnNo - 1].length;
      if (length === 1) return;
      // initializing the Merged rows
      for (let colStart = startColumn; colStart <= columnNo; colStart++) {
        for (let i = startRow; i < length; i++) {
          rowNodesArr[colStart - 1][i].attributes[0].value = 1;
          rowNodesArr[colStart - 1][i].style.display = "";
        }
      }
      const normalizeText = function (text) {
        return text.toLowerCase().replaceAll("\n", "").trim();
      };
      //  Merging the rows
      for (let col = 1; col <= columnNo; col++) {
        for (let row = startRow; row < length; ) {
          let nextRow = row + 1;
          while (
            nextRow < length &&
            normalizeText(rowNodesArr[col - 1][row].innerText) ===
              normalizeText(rowNodesArr[col - 1][nextRow].innerText)
          ) {
            // checking the column before to curr iteratin column has the same value or not
            if (
              col > 1 &&
              normalizeText(rowNodesArr[0][row].innerText) !==
                normalizeText(rowNodesArr[0][nextRow].innerText)
            ) {
              break;
            }
            rowNodesArr[col - 1][row].attributes[0].value++;
            rowNodesArr[col - 1][nextRow].style.display = "none";
            nextRow += 1;
          }
          row = nextRow;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@mixin align-cell-content {
  text-align: left;
  justify-items: flex-start;
  align-items: center;
}
@mixin cell-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_small,
    $app_font_size_1,
    $app_font_size_2
  );
}
.er-data-tables__pagination-page-selector {
  .el-select-dropdown__item {
    @include select-drop-down-item-style;
    &.selected {
      color: $selected-color;
    }
  }
}
.er-data-tables-server {
  $table-row-box-shadow: #e3d1d1;
  $table-row-border-color: #b3b3b3;
  $table-header-cell-font-color: #6bb5ff;
  // width: 1300px;
  .el-table__empty-text {
    // display: inline-flex;
    flex-direction: column;
    line-height: 25px;
    padding: 25px 0px;
    .no-data__img {
      width: 50px;
      height: 50px;
    }
    .no-data__text {
      text-transform: capitalize;
      @include responsiveProperty(font-size, 11px, 11px, 0.8em);
    }
  }
  .padding-10-0 {
    padding: 10px 0;
  }
  .el-table td .cell,
  .el-table th .cell {
    @include cell-font-size;
    @include align-cell-content;
    // * {
    //   @include align-cell-content;
    // }
  }
  .el-table .caret-wrapper {
    height: 22px;
    width: 10px;
    padding-left: 2px;
    .sort-caret {
      position: relative;
      &.ascending {
        left: 0px;
        top: 0px;
        margin-bottom: 2px;
      }
      &.descending {
        left: 0px;
        bottom: 0px;
      }
    }
  }
  .el-table .el-select {
    @include responsiveProperty(width, 120px, 140px, 180px);
  }

  .pagination-bar {
    margin-top: 10px;
    .el-pagination {
      .el-pagination__total {
        margin-left: 24px;
        align-self: center;
        @include cell-font-size;
      }
      .el-pagination__sizes,
      .el-pagination__jump {
        @include cell-font-size;
        align-self: center;
        .el-input {
          .el-input__inner {
            height: 22px;
            line-height: 22px;
            @include cell-font-size;
          }
          .el-input__icon {
            line-height: 22px;
            @include cell-font-size;
          }
        }
      }
      &.el-pagination {
        .el-select .el-input {
          @include responsiveProperty(min-width, 90px, 110px, 120px);
          margin: unset;
        }
      }
      &.pagination-with-slot {
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-prev .el-icon,
        .btn-next .el-icon,
        .el-pager .number {
          @include cell-font-size;
        }
        .el-pagination__rightwrapper {
          display: flex;
          width: 60%;
          justify-content: flex-end;
        }
        .el-pagination__leftwrapper {
          width: 40%;
          justify-content: flex-end;
          display: flex;
        }
        &.el-pagination button {
          display: inline-block;
          @include cell-font-size;
          height: unset;
          line-height: unset;
          vertical-align: top;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          &.er-button {
            display: inline-flex;
            align-items: center;
            span {
              height: unset;
              line-height: unset;
              @include responsiveProperty(font-size, 11px, 13px, 15px);
            }
          }
          span.material-icons-round {
            min-width: 16px;
          }
        }
      }
    }
  }
  .card-row-table {
    .el-table {
      padding: 10px;
    }
    .er-data-tables__header-row {
      box-shadow: 1px 1px 6px 2px $table-row-box-shadow;
    }
    .er-data-tables__row {
      box-shadow: 1px 1px 6px 2px $table-row-box-shadow;
    }

    .el-table__body,
    .el-table__header {
      border-spacing: 0px 12px;
      padding-left: 5px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: unset;
    }
    .el-table__header-wrapper,
    .el-table__body-wrapper {
      margin-right: 5px;
      margin-left: 5px;
    }
    .is-sortable {
      &.ascending .sort-caret.ascending {
        border-bottom-color: #ffffff;
      }
      &.descending .sort-caret.descending {
        border-top-color: #ffffff;
      }
    }
  }
  .white-header-table {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #1966a1;
      color: #fff;
    }

    .el-table .descending .sort-caret.descending {
      border-top-color: #1966a1;
    }

    .el-table .ascending .sort-caret.ascending {
      border-bottom-color: #1966a1;
    }
    .er-data-tables__border {
      .el-data-table--border {
        border: none;
      }
    }
    .el-table__header-wrapper {
      border-bottom: 0.9px solid #6c7b8a3b;
    }

    .el-table td,
    .el-table th {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .el-table--mini td,
    .el-table--mini th {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .el-table {
      .cell {
        @include responsiveProperty(line-height, 20px, 25px, 30px);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6px;
        padding-right: 6px;
        border: none;
        .el-tag {
          @include small-text;
          display: inline-flex;
          line-height: 20px;
          align-items: center;
          & + .el-tag {
            margin-right: 2px;
            margin-left: 2px;
          }
        }
        .el-input {
          @include cell-font-size;
        }
      }

      th.is-leaf {
        border-bottom: unset;
      }

      .el-table__header {
        th {
          background-color: white;
          color: #233a73;
          & > .cell {
            display: flex;
            -webkit-box-sizing: border-box;
            // border-right: 1px solid white;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            // padding-left: 10px;
            // padding-right: 10px;
            width: 100%;
            font-weight: 600;
            // height: 32px;
            line-height: 20px;
          }
        }
      }
      .el-table__expanded-cell {
        padding: 20px 28px;
      }

      .er-data-tables__header-row:nth-child(2) {
        display: none;
      }
    }
    .el-table td .cell:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-table__fixed-footer-wrapper tbody td {
      // border-top: 1px solid #ebeef5;
      background-color: #f9f9f9;
      color: #000000;
    }
    .el-table__footer-wrapper tbody td {
      background-color: #f9f9f9;
      color: #000000;
    }
    .el-table::before {
      background-color: #f9f9f9;
    }
    .el-table__fixed::before {
      background-color: #f9f9f9;
    }
    .er-button.el-button--text i {
      color: #66b1ff;
    }
  }
  .white-header-table-inner {
    .el-table td .cell,
    .el-table th .cell {
      @include cell-font-size;
      @include align-cell-content;
      * {
        @include align-cell-content;
      }
    }
    .el-table__header-wrapper {
      overflow-y: visible;
      position: initial;
      background-color: white;
      width: 100%;
    }

    .el-table .caret-wrapper {
      // height: 22px;
      width: 10px;
      padding-left: 2px;
    }

    .el-table--small td,
    .el-table--small th {
      padding: 0;
    }

    .el-table {
      .cell {
        @include responsiveProperty(line-height, 20px, 120%, 30px);
        // padding: 6px 0px 6px 0px;

        .el-tag {
          @include responsiveProperty(font-size, 1em, 0.7rem, 1em);
          display: inline-flex;
          align-items: center;
        }
      }
      th.is-leaf {
        border-bottom: unset;
      }

      .el-table__header {
        th {
          background-color: white;
          color: #233a73;
          & > .cell {
            display: flex;
            -webkit-box-sizing: border-box;
            // border-right: 1px solid white;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            // padding-left: 10px;
            // padding-right: 10px;
            width: 100%;
            font-weight: 600;
            // height: 32px;
            line-height: 20px;
          }
        }
      }

      .er-data-tables__header-row:nth-child(2) {
        display: none;
      }
    }
  }
  &.card-auto-height-scrollable__wrapper {
    height: 100%;
    // background-color: white;
    .pagination-bar {
      padding: 10px;
    }
    .card-auto-height-scrollable {
      &.sc-table {
        display: flex;
        flex-direction: column;
        min-height: 0;
        height: 100%;
        .el-table {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .el-table__body-wrapper {
            display: flex;
            flex-direction: column;
            min-height: 0;
            height: 100% !important;
          }
        }
      }
    }
  }

  .transparent-header-table {
    .el-table__body {
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0px 12px;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #1966a1;
      color: #fff;
    }

    .el-table .descending .sort-caret.descending {
      border-top-color: #1966a1;
    }

    .el-table .ascending .sort-caret.ascending {
      border-bottom-color: #1966a1;
    }

    .el-table td .cell,
    .el-table th .cell {
      @include cell-font-size;
      @include align-cell-content;
      * {
        @include align-cell-content;
      }
    }
    .er-data-tables__border {
      .el-data-table--border {
        border: none;
      }
    }
    .el-table td,
    .el-table th {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .el-table--mini td,
    .el-table--mini th {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .el-table {
      background: rgb(252, 252, 252);
      .cell {
        @include responsiveProperty(line-height, 20px, 25px, 30px);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 6px;
        padding-right: 6px;
        border: none;
        .el-tag {
          @include responsiveProperty(font-size, 1em, 0.7rem, 1em);
          display: inline-flex;
          align-items: center;
        }
        .el-input {
          @include cell-font-size;
        }
      }

      th.is-leaf {
        border-bottom: unset;
      }

      td:first-of-type {
        border-left: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      td:last-of-type {
        border-right: 1px solid #ebeef5;
        border-top: 1px solid #ebeef5;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .el-table__header {
        th {
          background-color: rgb(252, 252, 252);
          color: #6c7b8a;
          & > .cell {
            display: flex;
            -webkit-box-sizing: border-box;
            // border-right: 1px solid white;
            box-sizing: border-box;
            position: relative;
            vertical-align: middle;
            // padding-left: 10px;
            // padding-right: 10px;
            width: 100%;
            font-weight: 600;
            font-size: 13px !important;
            // height: 32px;
            line-height: 20px;
          }
        }
      }
      .el-table__expanded-cell {
        padding: 20px 28px;
      }

      .er-data-tables__header-row:nth-child(2) {
        display: none;
      }
    }
    .el-table td .cell:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-table__fixed-footer-wrapper tbody td {
      // border-top: 1px solid #ebeef5;
      background-color: #f9f9f9;
      color: #000000;
    }
    .el-table__footer-wrapper tbody td {
      background-color: #f9f9f9;
      color: #000000;
    }
    .el-table::before {
      background-color: #f9f9f9;
    }
    .el-table__fixed::before {
      background-color: #f9f9f9;
    }

    .el-button {
      padding: 0px;
    }
    // .el-button {
    //   padding: 0 !important;
    // }
  }
  .no-borders {
    .el-table--border td,
    .el-table__body-wrapper
      .el-table--border.is-scrolling-left
      ~ .el-table__fixed {
      border-top: none;
      border-left: none;
      border-right: none;
      // border-bottom: none;
    }
    .el-table--border th {
      border: none;
    }
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: transparent;
    z-index: 1;
  }
  .el-table--border,
  .el-table--group {
    border: none;
  }
}
</style>
