/** We will maintain filed level lengths in here */
const fieldLengths = {
  first_name: 24,
  last_name: 24,
  email: 254,
  mobile: 20,
  user_name: 254,
  otp: 6,
  min_password_length: 8,
  max_password_length: 254,
  MAX_ABW: 200,
  MAX_DOC: 999 // years * days
};
export default fieldLengths;
