/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: authErrorKeyMapMixin.js
Description: This mixin is useful for handling the common functionality related to user signup, signin , profile pages
*/
const authErrorKeyMapMixin = {
  computed: {
    aekmm__castErrorKeyToLang() {
      return errorObj => {
        return this.aekmm__authErrorLangKeyMapper(
          errorObj?.key,
          errorObj?.error_code
        );
      };
    },
    aekmm__keyToFieldTranslations: function() {
      return {
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        email: "Comn_email_address",
        password: "Usrs_pwd",
        current_password: "Usrs_old_password",
        confirm_password: "Comn_confirm_pwd",
        country: "Comn_country",
        name: "Comn_country",
        phone: "Comn_mobile_num",
        mobile: "Comn_mobile_num",
        timezone: "Comn_timezone",
        login_id: "user_name",
        otp: "OTP"
      };
    },
    aekmm__keyToInvalidTranslations: function() {
      return {
        first_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_3_char_for_field",
          VALUE_HAVE_MAX_CHAR: "max_24_char_for_field"
        },
        last_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_3_char_for_field",
          VALUE_HAVE_MAX_CHAR: "max_24_char_for_field"
        },
        email: {
          INVALID_VALUE: "email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "max_254_char_for_field",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_char_for_field"
        },
        login_id: {
          INVALID_VALUE: "Usrs_email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "Usrs_email_in_valid_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_email_in_valid_format"
        },
        password: {
          INVALID_VALUE: "Usrs_pwd_wrng_frmt",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          INVALID_PASSWORD: "Usrs_pwd_wrng_frmt"
        },
        confirm_password: {
          INVALID_VALUE: "Usrs_pwd_cnpwd_valida",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          VALUES_SHOULD_MATCH:
            "both_the_password_and_confirm_password_must_match",
          PASSWORD_CONFIRM_PASSWORD_NOT_SAME:
            "both_the_password_and_confirm_password_must_match"
        },
        country: { INVALID_VALUE: "Comn_country" },
        phone: { INVALID_VALUE: "Comn_mobile_num_valid" },
        mobile: { INVALID_VALUE: "Comn_mobile_num_valid" },
        timezone: { INVALID_VALUE: "Comn_timezone_not_valid" },
        otp: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "comn_otp_error_msg",
          INVALID_VALUE: "invalid_OTP"
        }
      };
    }
  },
  methods: {
    aekmm__authErrorLangKeyMapper: function(key, error_code) {
      const keyToField = this.aekmm__keyToFieldTranslations;
      const keyToInvalid = this.aekmm__keyToInvalidTranslations;
      const fieldTranslation = this.$t(keyToField[key]);
      switch (error_code) {
        case "FIELD_REQUIRED":
          return this.$t("Comn_field_is_required", { field: fieldTranslation });
        case "INVALID_VALUE":
          return this.$t(keyToInvalid[key].INVALID_VALUE);
        case "VALUES_SHOULD_MATCH":
          return this.$t(keyToInvalid[key].VALUES_SHOULD_MATCH);
        case "VALUE_DO_NOT_HAVE_MIN_CHAR":
          return this.$t(keyToInvalid[key].VALUE_DO_NOT_HAVE_MIN_CHAR, {
            field: fieldTranslation
          });
        case "VALUE_HAVE_MAX_CHAR":
          return this.$t(keyToInvalid[key].VALUE_HAVE_MAX_CHAR, {
            field: fieldTranslation
          });
        default:
          if (key && error_code) return this.$t(keyToInvalid[key][error_code]);
      }
    }
  }
};
export default authErrorKeyMapMixin;
