<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name:necessaryUserDetails.vue
Description:This is the child component of the index.vue.This files shows the information about the details of the user for the first time logins befores proceeding further
-->
<template>
  <el-card
    shadow="never"
    v-loading="pageLoading"
    class="user-profile-necessary-info-card"
  >
    <el-row class="user-profile-necessary-info">
      <el-row>
        <h2 style="padding: 10px 0px">{{ `${$t("Usrs_profile")}` }}</h2>
        <el-tag>* {{ `${$t("Confirm_below_data_before_prceceed")}` }}</el-tag>
      </el-row>

      <el-row type="flex" v-loading="profileLoading">
        <er-form
          ref="userDetailsForm"
          label-position="left"
          size="small"
          :model="user"
        >
          <!-- <el-row type="flex" :gutter="24"> -->
          <el-col>
            <er-form-item
              :label="userFormLabels[0].label"
              :error="
                aekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)
              "
            >
              <el-input
                :disabled="disabled"
                v-model="user.first_name"
                :placeholder="userFormLabels[0].placeholder"
                :maxlength="userFieldLengths.first_name"
              ></el-input>
            </er-form-item>
            <er-form-item
              :label="userFormLabels[1].label"
              :error="
                aekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)
              "
            >
              <el-input
                :disabled="disabled"
                v-model="user.last_name"
                :placeholder="userFormLabels[1].placeholder"
                :maxlength="userFieldLengths.last_name"
              ></el-input>
            </er-form-item>
            <er-form-item
              :label="userFormLabels[2].label"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
            >
              <el-input
                :disabled="true"
                :value="user.email"
                :placeholder="userFormLabels[2].placeholder"
              ></el-input>
            </er-form-item>
            <er-form-item
              :label="userFormLabels[3].label"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.mobile)"
            >
              <el-input
                :disabled="true"
                :value="user.mobile"
                :placeholder="userFormLabels[3].placeholder"
              ></el-input>
            </er-form-item>
            <er-form-item
              :label="userFormLabels[4].label"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.country)"
            >
              <er-select
                :value="user.country"
                value-key="code"
                autocomplete="new-country"
                :disabled="true"
                filterable
                :placeholder="userFormLabels[4].placeholder"
              >
                <er-option
                  v-for="(name, code) in countries"
                  :key="code"
                  :label="name"
                  :value="{ name, code }"
                ></er-option>
              </er-select>
            </er-form-item>
            <er-form-item
              :label="userFormLabels[5].label"
              :error="
                aekmm__castErrorKeyToLang(ehm__errMessagesObject.timezone)
              "
            >
              <!-- autocomplete="new-timezone" -->
              <er-select
                :value="user.timezone"
                autocomplete="new-timezone"
                value-key="name"
                :disabled="disabled"
                filterable
                :placeholder="userFormLabels[5].placeholder"
                @change="handleChangeTimeZone"
              >
                <er-option
                  v-for="(timezone, name) in getTimeZones"
                  :key="name"
                  :label="name"
                  :value="timezone"
                ></er-option>
              </er-select>
            </er-form-item>
          </el-col>
          <!-- </el-row> -->
        </er-form>
      </el-row>
      <el-row type="flex" justify="end">
        <er-button
          btnType="save"
          size="mini"
          :showLabel="true"
          :showIcon="true"
          :disabled="disabled"
          @click="submitUpdatedDetails"
        ></er-button>
      </el-row>
    </el-row>
  </el-card>
</template>

<script>
import User from "@/model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import {
  erFormItem,
  erOption,
  erSelect,
  erForm
} from "@/components/base/basePondlogs";
import fieldLengths from '@/constants/fieldLengths';

export default {
  mixins: [errorHandlerMixin, authErrorKeyMapMixin],
  components: {
    erFormItem,
    erForm,
    erOption,
    erSelect
  },
  data: function () {
    return {
      userFieldLengths: fieldLengths,
      src: "",
      disabled: false,
      user: new User(),
      ehm__errMessagesObject: new User(),
      countries: {},
      profileLoading: false,
      pageLoading: false,
      timezones: {}
    };
  },
  computed: {
    getTimeZones() {
      const timezonesArr = Object.values(
        this.$store.getters["user/getTimeZones"]
      ).map((x) => ({
        name: x.name,
        offset: x.offset
      }));
      const timeZoneObj = {};
      timezonesArr.forEach((x) => {
        timeZoneObj[x.name] = x;
      });
      return timeZoneObj;
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_timezone"),
          placeholder: this.$t("Usrs_select_timezone")
        }
      ];
    }
  },
  mounted() {
    this.getUserProfile();
    this.ehm__errMessagesObject.country = "";
    this.$store
      .dispatch("auth/fetchCountryList")
      .then((response) => {
        this.countries = this.$store.getters["auth/getCountries"];
      })
      .catch((err) => {
        this.ehm__errorMessages(err, true);
      });
  },
  methods: {
    getUserProfile() {
      this.pageLoading = true;
      this.$store
        .dispatch("user/fetchUserProfile")
        .then(async (userDetails) => {
          this.user = userDetails;
          await this.handleCountryChange(this.user.country);
        })
        .catch((err) => {
          this.ehm__errorMessages(err, true);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    async handleCountryChange(selectedCountry) {
      this.pageLoading = true;
      try {
        if (selectedCountry && selectedCountry.code) {
          await this.$store.dispatch(
            "user/fetchTimeZones",
            selectedCountry.code
          );
          this.user.timezone = Object.values(this.getTimeZones)[0];
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }

      this.pageLoading = false;
    },
    handleChangeTimeZone(timezone) {
      this.user.timezone = timezone;
      this.user = Object.assign({}, this.user);
    },
    submitUpdatedDetails() {
      this.profileLoading = true;
      this.ehm__errMessagesObject = new User();
      this.ehm__errMessagesObject.country = "";
      this.$store
        .dispatch("user/updateUserProfile", { first_name: this.user.first_name, last_name: this.user.last_name, timezone: this.user.timezone, country: this.user.country })
        .then((response) => {
          this.userErrorMsg = new User();
          this.$notify({
            title: this.$t("Usrs_edit_profile"),
            message: this.$t("Usrs_profile_upd_success"),
            duration: 5000,
            type: "success"
          });
          this.$router.go();
        })
        .catch((err) => {
          console.log(JSON.stringify(err));
          this.ehm__errorMessages(err, true);
        })
        .finally(() => {
          this.profileLoading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.user-profile-necessary-info-card{
  margin: 10px auto;
  @include responsiveProperty(width, 50%, 50%, 30%);
}
.user-profile-necessary-info {
  overflow-y: auto;
  h4 {
    color: #3a9cff;
  }
  .user-profile-header {
    width: 60%;
  }
  .el-form-item__error {
    padding-left: 1em;
  }
  .er-form {
    margin-top: 20px;
    .er-form-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      .el-form-item__label {
        color: #000000;
        font-size: 0.9em;
        font-weight: 700;
        width: 33%;
        line-height: unset;
        height: unset;
      }
      .el-form-item__content {
        width: 65%;
        display: flex;
        .el-input__inner {
          color: #000000;
        }
        &:before {
          content: ":";
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
